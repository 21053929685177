import {Widget} from "../components/Common";
import FlexStack from "../components/Common/flex-stack";
import {useSuspenseQuery} from "@tanstack/react-query";
import {AdminBreederService} from "../services/admin-breeder-service";
import {TableContainer, TextField} from "@mui/material";
import React, {useMemo, useState} from "react";
import {DataTable} from "../components/Table/data-table";
import {RegistryService} from "../services/registry-service";
import {BreederAdminController$BreederListJson} from "../api/generated/breeder-controller";
import Tabbed from "../components/Common/tabbed";
import Autocomplete from "@mui/material/Autocomplete";
import {RegistryDto} from "../api/generated/registry-service";
import {InfoBox} from "../components/Common/alerts";
import {LoadingSuspense} from "../components/Common/loading-suspense";

const useBreederList = () => useSuspenseQuery({
    queryKey: ['admin', 'breeder', {list: 'all'}],
    queryFn: async () => await AdminBreederService.list(),
});

const useIndividualBreederList = () => useSuspenseQuery({
    queryKey: ['admin', 'breeder', {list: 'individual'}],
    queryFn: async () => await AdminBreederService.listIndividuals(),
});

const useRegistryBreederList = (registryId: string) => useSuspenseQuery({
    queryKey: ['admin', 'breeder', {list: 'registry', registryId: registryId}],
    queryFn: async () => await AdminBreederService.listOfRegistry(registryId),
});

const useRegistriesList = () => useSuspenseQuery({
    queryKey: ['admin', 'registries', {list: 'all'}],
    queryFn: async () => await RegistryService.findAll(),
});

const containsFilterValue = (filterValue: string) => (breeder: BreederAdminController$BreederListJson) => Object.entries(breeder)
    .filter(([k]) => k !== 'id')
    .map(entry => entry[1])
    .flatMap(value => typeof value === 'string' ? [value] : Object.values(value))
    // .filter((value): value is string => typeof value === 'string')
    .map(value => String(value).toLowerCase())
    .some(value => value.includes(filterValue));

const BreederTable = ({breeders}: { breeders: BreederAdminController$BreederListJson[] }) => {
    const [filter, setFilter] = useState('');

    const filteredBreeders = useMemo(
        () => breeders.filter(containsFilterValue(filter.toLowerCase())),
        [breeders, filter]);

    return (
        <>
            <TextField
                variant="outlined"
                fullWidth
                placeholder="Filter"
                value={filter}
                onChange={ev => setFilter(ev.target.value)}/>

            <p>{filteredBreeders.length}/{breeders.length} Züchter</p>

            <TableContainer>
                <DataTable data={filteredBreeders} columns={[
                    {id: 'name', header: 'Name', accessor: 'name'},
                    {id: 'farmName', header: 'Hofname', accessor: 'farmName'},
                    {id: 'email', header: 'E-Mail', accessor: 'emailAddress'},
                    {id: 'signupDate', header: 'Registrierdatum', accessor: 'signupDate', render: d => d.toLocaleString()},
                    {
                        id: 'address', header: 'Adresse', accessor: 'address', render: addr => (
                            <>
                                <div>{addr.street}</div>
                                <div>{String([addr.zipCode, addr.city, addr.state].filter(Boolean).join(', '))}, {addr.country}</div>
                            </>
                        )
                    },
                ]}/>
            </TableContainer>
        </>
    );
};

const AssociationBreederTable = ({registryId}: { registryId: string }) => {
    const {data: breeders} = useRegistryBreederList(registryId);

    return <BreederTable breeders={breeders}/>;
};

const AssociationBreeders = ({registries}: { registries: RegistryDto[] }) => {
    const [selectedRegistry, setSelectedRegistry] = useState(registries[0]);

    return (
        <>
            <Autocomplete
                disablePortal
                disableClearable
                options={registries}
                value={selectedRegistry}
                getOptionLabel={option => `${option.name}, ${option.membersCount} members`}
                onChange={(event, value) => setSelectedRegistry(value)}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Registry"/>}/>

            <LoadingSuspense>
                <AssociationBreederTable registryId={selectedRegistry.id}/>
            </LoadingSuspense>
        </>
    );
};

export const AdminBreedersList = () => {
    const {data: breeders} = useBreederList();
    const {data: registries} = useRegistriesList();
    const {data: individualBreeders} = useIndividualBreederList();

    return (
        <FlexStack>
            <Widget displayName="Züchter">
                <Tabbed>
                    <Tabbed.Tab label={`All ${breeders.length}`}>
                        <BreederTable breeders={breeders}/>
                    </Tabbed.Tab>
                    <Tabbed.Tab label={`Associations ${registries.length}`}>
                        <LoadingSuspense>
                            {registries.length
                                ? <AssociationBreeders registries={registries}/>
                                : <InfoBox>No associations configured</InfoBox>}
                        </LoadingSuspense>
                    </Tabbed.Tab>
                    <Tabbed.Tab label={`Individual users ${individualBreeders.length}`}>
                        <BreederTable breeders={individualBreeders}/>
                    </Tabbed.Tab>
                </Tabbed>
            </Widget>
        </FlexStack>
    );
};