import {Widget} from "../Common";
import {Alert, Button, LinearProgress} from "@mui/material";
import React from "react";
import TabularTextView from "./Common/tabular-text-view";
import {BreederService} from "../../services/breeder-service";
import {useQuery, useSuspenseQuery} from "@tanstack/react-query";
import RouteService from "../../services/route-service";
import {useNav} from "../Common/hooks/use-nav";

type OwnerWidgetProps = { ownerId: string; panonId: string; };

const useBreederInfo = () => useSuspenseQuery({
    queryKey: ['breeder', 'info'],
    queryFn: () => BreederService.info(),
});

const OwnerWidgetContent = ({ownerId, panonId}: OwnerWidgetProps) => {
    const {data: ownerDetails, isLoading, isError} = useQuery({
        queryKey: ['breeder', 'details', {id: ownerId}],
        queryFn: () => BreederService.getDetails(ownerId),
    });

    if (isLoading) {
        return (
            <LinearProgress/>
        );
    }

    if (isError || !ownerDetails) {
        return (
            <Alert severity="error">Fehler beim Laden der Besitzerdaten.</Alert>
        );
    }

    const displayPairs = new Map([
        ['Benutzername', ownerDetails.username],
        ['Herdkürzel', ownerDetails.herdCode],
    ]);

    return (
        <>
            <TabularTextView displayPairs={displayPairs}/>
        </>
    );
};

export const OwnerWidget = ({ownerId, panonId}: OwnerWidgetProps) => {
    const {data: breeder} = useBreederInfo();
    const nav = useNav();

    const showHerdAnimal = () => nav.push(RouteService.ANIMAL_DETAILS, {panonId});

    return (
        <Widget displayName={
            <>
                Besitzer
                {ownerId === breeder.id
                    ? <Button onClick={showHerdAnimal}>Tier in Herde anzeigen</Button>
                    : null}
            </>
        }>
            <OwnerWidgetContent ownerId={ownerId} panonId={panonId}/>
        </Widget>
    );
};