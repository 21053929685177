import React from 'react';
import {LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack} from '@mui/material';
import {RouteComponentProps, withRouter} from "react-router-dom";
import FlexStack from "../components/Common/flex-stack";
import {BreederService} from "../services/breeder-service";
import {Widget} from "../components/Common";
import TabularTextView from "../components/AnimalDashboard/Common/tabular-text-view";
import {Female, Male} from "@mui/icons-material";
import {Sex} from "../api/generated/herd-animal";
import {ErrorBox} from '../components/Common/alerts';
import { useQuery } from '@tanstack/react-query';

const useBreeder = (id: string) => {
    const queryInfo = useQuery({
        queryKey: ['breeder', id],
        queryFn: async () => BreederService.getDetails(id),
        enabled: !!id,
    });

    return {
        ...queryInfo,
        breeder: queryInfo.data,
    };
};

const useRegisteredAnimals = (ownerId: string) => {
    const queryInfo = useQuery({
        queryKey: ['breeder', 'animals', ownerId],
        queryFn: async () => BreederService.registeredAnimals(ownerId),
        enabled: !!ownerId,
    });

    return {
        ...queryInfo,
        registeredAnimals: queryInfo.data,
    };
};

type CompareFn<T> = (a: T, b: T, thenBy?: CompareFn<T>) => number;
const sortBy = <T, P>(prop: (t: T) => P, thenBy: CompareFn<T> = () => 0) => (a: T, b: T) => {
    const propA = prop(a);
    const propB = prop(b);
    if (propA < propB) {
        return -1;
    }
    if (propA > propB) {
        return 1;
    }
    return thenBy(a, b);
};

const SexIcon = ({sex}: { sex: Sex }) => sex === 'MALE'
    ? <Male titleAccess="Hengst"/>
    : <Female titleAccess="Stute"/>

const RegisteredAnimalsList = ({ownerId}: { ownerId: string; }) => {
    const {registeredAnimals, isLoading, isError} = useRegisteredAnimals(ownerId);

    if (isLoading) {
        return <Skeleton width="100%"/>;
    }

    if (isError || !registeredAnimals) {
        return <ErrorBox>Fehler beim Laden der Registrierungen!</ErrorBox>;
    }

    registeredAnimals.sort(sortBy(a => a.herdCode, sortBy((a) => a.name)));

    return (
        <List>
            {registeredAnimals.map(registration =>
                <ListItem key={registration.panonId.id}>
                    <ListItemAvatar>
                        <SexIcon sex={registration.sex}/>
                    </ListItemAvatar>
                    <ListItemText primary={`${registration.herdCode} ${registration.name}`}
                                  secondary={`${registration.breed} | ${registration.registryName}`}
                    />
                </ListItem>
            )}
        </List>
    );
};

const BreederDetails: React.FC<RouteComponentProps<{ breederId: string }>> = ({match}) => {
    const {breederId} = match.params;
    const {breeder, isLoading, isError} = useBreeder(breederId);

    if (isLoading) {
        return (
            <Stack>
                <LinearProgress/>

                <Widget displayName="Züchter">
                    <Skeleton width="100%"/>
                </Widget>
            </Stack>
        );
    }

    if (isError || !breeder) {
        return <ErrorBox>Fehler beim Laden des Züchters</ErrorBox>;
    }

    const baseInfo = new Map([
        ['Farm Name', breeder.farmName],
        ['Herdkürzel', breeder.herdCode],
        ['Besitzer', breeder.owners.join(', ')],
        ['Email', breeder.emailAddress]
    ]);

    return (
        <FlexStack>
            <Widget displayName="Züchter">
                <TabularTextView displayPairs={baseInfo}/>
            </Widget>

            <Widget displayName="Registrierte Tiere">
                <RegisteredAnimalsList ownerId={breederId}/>
            </Widget>
        </FlexStack>
    );
};

export default withRouter(BreederDetails);
