import React, {Fragment, FunctionComponent, PropsWithChildren} from 'react';
import TextField from '@mui/material/TextField';
import {DatePicker} from '@mui/x-date-pickers';
import {TreatmentListJson} from '../../../api/generated/medical-rest';
import {Box, Button, Checkbox, FormControlLabel, SxProps} from "@mui/material";
import {HealthAndSafety, HealthAndSafetyOutlined} from "@mui/icons-material";

export type TreatmentFormProps = TreatmentFormBaseProps & PropsWithChildren<{
    onTimestampChange: (newDate: any) => void;
    onNoteChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onPrivateChange: (isPrivate: boolean) => void;
    treatment: TreatmentListJson;
    hasError?: boolean;
}>;

export interface TreatmentFormBaseProps {
    onFormSave: (treatment: TreatmentListJson) => void
    onFormCancel: () => void
}

const styles: Record<string, SxProps> = {
    button: {
        margin: 2
    }
} as const;

const TreatmentForm: FunctionComponent<TreatmentFormProps> = (props) => {
    const {treatment, hasError} = props;

    return (
        <Fragment>
            {props.children}
            <DatePicker
                label="Durchgeführt am"
                value={treatment.timestamp}
                onChange={props.onTimestampChange}
            />
            <TextField
                id="note-textarea"
                label="Notiz"
                placeholder="Notiz"
                value={treatment.note}
                onChange={props.onNoteChange}
                multiline
            />
            <FormControlLabel
                label={treatment.isPrivate ? 'Von Export exkludiert' : 'Im Export sichtbar'}
                control={
                    <Checkbox
                        icon={<HealthAndSafetyOutlined/>}
                        checkedIcon={<HealthAndSafety/>}
                        checked={treatment.isPrivate}
                        onChange={(ev) => props.onPrivateChange(ev.target.checked)}/>
                }/>
            <Box display="flex" justifyContent="center">
                <Button className="tags-cancel-button" sx={styles.button} variant="outlined" color="primary" onClick={props.onFormCancel}>Abbrechen</Button>
                <Button className="tags-save-button"
                        disabled={hasError}
                        sx={styles.button}
                        variant="contained"
                        color="primary"
                        onClick={() => props.onFormSave(treatment)}>
                    Speichern
                </Button>
            </Box>
        </Fragment>
    );
};

export default TreatmentForm;
