// @flow
import React, {FunctionComponent} from 'react';
import {useHistory, useLocation, useParams} from "react-router-dom";
import FlexStack from "../components/Common/flex-stack";
import TreatmentType from "../api/dtos/treatment-type";
import MedicationForm from "../components/AnimalDashboard/TreatmentDialog/medication-form";
import SimpleNumberValueForm from "../components/AnimalDashboard/TreatmentDialog/simple-number-value-form";
import qs from "qs";
import {TreatmentListJson} from "../api/generated/medical-rest";
import {DateTime} from "luxon";
import TreatmentService from "../services/treatment-service";
import RouteService from "../services/route-service";
import SimpleNoteForm from "../components/AnimalDashboard/TreatmentDialog/simple-note-form";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

type TreatmentEditorPathParams = {
    animalId: string;
};

export type TreatmentEditorLocationState = {
    treatment: TreatmentListJson;
    backLocation?: string;
};

const TreatmentEditor: FunctionComponent = () => {
    const history = useHistory();
    const {animalId} = useParams<TreatmentEditorPathParams>();
    const {state, search} = useLocation<TreatmentEditorLocationState>();
    const {t} = useTranslation();

    const queryClient = useQueryClient();
    const {mutateAsync: saveTreatment} = useMutation({
        mutationFn: async (treatment: TreatmentListJson) => treatment.id
            ? await TreatmentService.updateTreatment(treatment)
            : await TreatmentService.addTreatment(treatment),
        onSettled: async () => await Promise.all([
            queryClient.invalidateQueries({queryKey: ['treatment']}),
            queryClient.invalidateQueries({queryKey: ['treatments']}),
            queryClient.invalidateQueries({queryKey: ['pregnancy']}),
            queryClient.invalidateQueries({queryKey: ['pregnancies']}),
        ]),
    });

    const parsedQueryString = qs.parse(search, {ignoreQueryPrefix: true});
    const treatmentType = String(parsedQueryString.treatmentType);
    const panonId = String(parsedQueryString.panonId);

    const treatmentTemplate: TreatmentListJson = state?.treatment ?? {
        animalId,
        dose: 0,
        drug: '',
        id: '',
        note: '',
        timestamp: DateTime.now(),
        type: '',
        unit: '',
        value: 0,
        private: false,
        isPrivate: false,
    };
    treatmentTemplate.isPrivate = treatmentTemplate.private; // fix private property for writing

    const navigateBack = () => history.push(state?.backLocation
        ? state?.backLocation
        : RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId}));
    const handleTreatmentFormCancel = navigateBack;

    const handleTreatmentSave = async (treatment: TreatmentListJson) => {
        await saveTreatment(treatment);
        navigateBack();
    };

    const renderFormForType = () => {
        switch (treatmentType) {
            case TreatmentType.vitaminsAndMinerals:
            case TreatmentType.otherMedication:
            case TreatmentType.vaccination:
            case TreatmentType.antiparasitics:
                return <MedicationForm prefill={{...treatmentTemplate, type: treatmentType, unit: "ml"}} onFormSave={handleTreatmentSave} onFormCancel={handleTreatmentFormCancel}/>;
            case TreatmentType.bodyscore:
                return <SimpleNumberValueForm prefill={{...treatmentTemplate, type: treatmentType}} valueFormHelperText="Bodyscore" onFormSave={handleTreatmentSave}
                                              onFormCancel={handleTreatmentFormCancel}/>
            case TreatmentType.weighing:
                return <SimpleNumberValueForm prefill={{...treatmentTemplate, type: treatmentType, unit: "kg"}} valueFormHelperText="Gewicht" onFormSave={handleTreatmentSave}
                                              onFormCancel={handleTreatmentFormCancel}/>
            case TreatmentType.ultrasound:
            case TreatmentType.spittest:
                return <SimpleNoteForm prefill={{...treatmentTemplate, type: treatmentType}} onFormSave={handleTreatmentSave} onFormCancel={handleTreatmentFormCancel}/>
            case TreatmentType.other:
                return <SimpleNoteForm prefill={{...treatmentTemplate, type: treatmentType}} onFormSave={handleTreatmentSave} onFormCancel={handleTreatmentFormCancel}/>
            default:
                return <MedicationForm prefill={{...treatmentTemplate, type: TreatmentType.otherMedication}} onFormSave={handleTreatmentSave}
                                       onFormCancel={handleTreatmentFormCancel}/>;
        }
    };

    const title = t(treatmentType);

    return (
        <FlexStack>
            <Typography variant="h4">{title === treatmentType ? 'Sonstige Behandlung' : title}</Typography>
            {renderFormForType()}
        </FlexStack>
    );
};

export default TreatmentEditor
