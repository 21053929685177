import {QueryClient, useMutation, useQueryClient} from "@tanstack/react-query";
import {AnimalBaseData} from "../../../api/dtos/animal-base-data";
import {AnimalService} from "../../../services/animal-service";
import {JsonAnimal} from "../../../api/generated/rest-dto";
import {PanonIdentifier} from "../../../api/generated/herd-animal";

const invalidateAnimalQueries = (queryClient: QueryClient) => Promise.all([
    queryClient.invalidateQueries({queryKey: ['herd']}),
    queryClient.invalidateQueries({queryKey: ['animals']}),
    queryClient.invalidateQueries({queryKey: ['animal']}),
]);

export const useUpdateBaseData = (animal: JsonAnimal | undefined) => {
    const queryClient = useQueryClient();
    const {mutate: updateBaseData, ...rest} = useMutation({
        mutationFn: async (update: AnimalBaseData) => animal ? await AnimalService.updateBaseData(animal, update) : null,
        onSettled: () => invalidateAnimalQueries(queryClient),
    });

    return {...rest, updateBaseData};
};

export const useDeactivateAnimal = () => {
    const queryClient = useQueryClient();
    const {mutateAsync: deactivateAnimal, ...rest} = useMutation({
        mutationFn: async (panonId: PanonIdentifier) => await AnimalService.deactivateAnimal(panonId),
        onSettled: () => invalidateAnimalQueries(queryClient),
    });

    return {...rest, deactivateAnimal};
};

export const useActivateAnimal = () => {
    const queryClient = useQueryClient();
    const {mutateAsync: activateAnimal, ...rest} = useMutation({
        mutationFn: async (panonId: PanonIdentifier) => await AnimalService.activateAnimal(panonId),
        onSettled: () => invalidateAnimalQueries(queryClient),
    });

    return {...rest, activateAnimal};
};
