import {Avatar, ListItem, ListItemAvatar, Skeleton, Stack} from "@mui/material";
import React from "react";

export const LoadingAvatarListItem = () => (
    <ListItem>
        <ListItemAvatar><Avatar/></ListItemAvatar>
        <Stack width="100%">
            <Skeleton width="100%"/>
            <Skeleton width="100%"/>
        </Stack>
    </ListItem>
);

