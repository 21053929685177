import React, {useState} from 'react';
import {Box, Button, InputAdornment, SxProps, TextField, Theme} from "@mui/material";
import {DateTime} from "luxon";
import {JsonAnimal} from "../api/generated/rest-dto";
import useFormFields from "../components/Common/hooks/use-form-fields";
import {useHistory, useLocation} from "react-router-dom";
import FlexStack from "../components/Common/flex-stack";
import {PregnancyService} from "../services/pregnancy-service";
import RouteService from "../services/route-service";
import {SexValues} from "../api/generated/herd-animal";
import {DatePicker} from "@mui/x-date-pickers";
import ParentPicker from "../components/Pedigree/parent-picker";
import {useGlobalSnackbarStore} from "../stores/global-snackbar-store";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {CreatePregnancyJson} from "../api/generated/medical-rest";
import {LoadingSuspense} from "../components/Common/loading-suspense";
import {PregnancyDurationPicker} from "./pregnancy-duration-picker";

type PregnancyLocationState = {
    mother: JsonAnimal
};

const styles: Record<string, SxProps<Theme>> = {
    button: {
        margin: 1
    }
} as const;

const DEFAULT_PREGNANCY_DURATION = 350;

const useCreatePregnancy = ({sourceRoute}: { sourceRoute: string }) => {
    const {error, success} = useGlobalSnackbarStore((state) => state);

    const queryClient = useQueryClient();
    const {mutateAsync: createPregnancy} = useMutation({
        mutationFn: async (data: CreatePregnancyJson) => await PregnancyService.createPregnancy(data),
        onSuccess: () => success('Trächtigkeit wurde angelegt!', sourceRoute),
        onError: () => error('Fehler beim Anlegen der Trächtigkeit'),
        onSettled: async () => await Promise.all([
            queryClient.invalidateQueries({queryKey: ['pregnancies']}),
            queryClient.invalidateQueries({queryKey: ['pregnancy']}),
        ]),
    });
    return {
        createPregnancy
    };
};

const PregnancyCreateForm = ({mother}: { mother: JsonAnimal }) => {
    const startDate = DateTime.now();
    const initialFormValues = {
        actualEndDate: null,
        sireId: "",
        damId: "",
        note: "",
        startDate,
    };

    const {error} = useGlobalSnackbarStore((state) => state);

    const history = useHistory();

    const sourceRoute = RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId: mother.panonIdentifier.id});

    const [selectedSire, setSelectedSire] = useState<JsonAnimal>();
    const {formFields, createChangeHandler} = useFormFields({
        ...initialFormValues,
        duration: DEFAULT_PREGNANCY_DURATION
    });

    const calculatedEndDate = formFields.startDate.plus({days: formFields.duration ?? 0});
    const {createPregnancy} = useCreatePregnancy({sourceRoute});

    const handleSireSelected = (selectedSire: JsonAnimal) => {
        if (selectedSire.sex === SexValues.MALE) { //just to make sure we're setting a male as sire
            setSelectedSire(selectedSire);
        } else {
            error('Das Geschlecht des Vaters muss "Männlich" sein!');
        }
    }

    const handleSubmit = () => createPregnancy({
        ...formFields,
        calculatedEndDate: calculatedEndDate,
        motherAnimalId: mother.panonIdentifier.id,
        fatherAnimalId: selectedSire?.panonIdentifier.id ?? null,
    });

    const onDurationChange = createChangeHandler("duration", value => !isNaN(value) ? parseInt(value) : 0);
    return (
        <FlexStack>
            <DatePicker
                label="Trächtigkeitsbeginn"
                value={formFields.startDate}
                onChange={createChangeHandler("startDate")}
            />

            <TextField type="number"
                       label="Erwartete Schwangerschaftsdauer"
                       slotProps={{
                           input: {
                               endAdornment: <InputAdornment position="end">Tage</InputAdornment>,
                               slotProps: {
                                   input: {
                                       min: 0,
                                   },
                               },
                           },
                       }}
                       value={formFields.duration}
                       onChange={onDurationChange}/>

            <PregnancyDurationPicker
                dam={mother}
                onPick={days => onDurationChange(days)}
                days={formFields.duration}
                defaultDuration={DEFAULT_PREGNANCY_DURATION}/>

            <DatePicker
                disabled
                label="Errechneter Geburtstermin"
                value={calculatedEndDate}
            />

            <DatePicker
                label="Tatsächlicher Geburtstermin"
                value={formFields.actualEndDate}
                onChange={createChangeHandler("actualEndDate")}
            />
            <TextField
                id="note-textarea"
                label="Notiz"
                placeholder="Notiz"
                value={formFields.note}
                onChange={createChangeHandler("note")}
                multiline
            />
            <ParentPicker onParentSelected={handleSireSelected} damEditable={false} dam={mother} sire={selectedSire}/>
            <Box>
                <Button sx={styles.button} onClick={() => history.push(sourceRoute)} variant="outlined" color="primary">
                    Abbrechen
                </Button>
                <Button sx={styles.button} onClick={handleSubmit} variant="contained" color="primary">
                    Speichern
                </Button>
            </Box>
        </FlexStack>
    );
};

const PregnancyCreate = () => {
    const history = useHistory();
    const {state: locationState} = useLocation<PregnancyLocationState>();

    //Guard against direct calls of the page without any locationState.
    if (!(locationState?.mother)) {
        history.push(RouteService.HOME);
        return null;
    }

    return (
        <LoadingSuspense>
            <PregnancyCreateForm mother={locationState.mother}/>;
        </LoadingSuspense>
    );
};

export default PregnancyCreate;
