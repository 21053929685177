// @flow
import * as React from 'react';
import {useMemo, useState} from 'react';
import Widget, {SortDirection} from "../../Common/widget";
import TreatmentFilterDialog, {TreatmentFilterOptions} from "./treatment-list-filter-dialog";
import TreatmentType from "../../../api/dtos/treatment-type";
import {TreatmentListJson} from "../../../api/generated/medical-rest";
import RouteService from "../../../services/route-service";
import {useHistory} from "react-router-dom";
import {TreatmentEditorLocationState} from "../../../pages/treatment-editor";
import {SortConfiguration, SortFunction, TreatmentListProps} from './treatment-list-types';
import {PaginatedTreatmentList} from "./paginated-treatment-list";
import {Alert, Chip, List, ListItem, Switch} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LockPerson} from "@mui/icons-material";


const sortFunctions: { [fieldName: string]: SortFunction } = {
    "Datum": (a: TreatmentListJson, b: TreatmentListJson) => a.timestamp.toMillis() - b.timestamp.toMillis() //TODO i18n the key!!
};

const filterTreatments = (treatments: TreatmentListJson[], options: TreatmentFilterOptions): TreatmentListJson[] => {
    const includedTypes: Set<string> = new Set(options.includedTypes ?? []);
    return treatments.filter(t => includedTypes.has(t.type));
};

const sortTreatments = (treatments: TreatmentListJson[], fkt: SortFunction): TreatmentListJson[] => {
    return fkt ? [...treatments].sort(fkt) : treatments;
}

const getSortFunction = (sortFunctionName: string, direction: SortDirection) => {
    const fkt = sortFunctions[sortFunctionName];
    return direction === SortDirection.ASC ?
        (a: TreatmentListJson, b: TreatmentListJson) => fkt(a, b) :
        (a: TreatmentListJson, b: TreatmentListJson) => -fkt(a, b);
}

const FilterChips = ({includedTypes}: { includedTypes: string[] }) => {
    const {t} = useTranslation();

    return (
        <List sx={{display: 'flex', flexWrap: 'wrap'}} disablePadding>
            {includedTypes.map(type => [type, t(type)])
                .sort((a, b) => a[1].localeCompare(b[1]))
                .map(type => (
                    <ListItem key={type[0]} sx={{width: 'auto', px: 0.5}}>
                        <Chip label={t(type[1])}/>
                    </ListItem>
                ))}
        </List>
    );
};

const EXCLUDED_TREATMENT_TYPES: readonly TreatmentType[] = [TreatmentType.weighing, TreatmentType.bodyscore, TreatmentType.ultrasound, TreatmentType.spittest] as const;
const DEFAULT_FILTER_TYPES = Object.values(TreatmentType)
    .filter(t => !EXCLUDED_TREATMENT_TYPES.includes(t));

const TreatmentList: React.FC<TreatmentListProps> = (props) => {
    const {animal, treatments} = props;
    const history = useHistory();

    const [sort, setSort] = useState<SortConfiguration>({name: "Datum", direction: SortDirection.DESC})
    const [filterDialogOpen, setFilterDialogOpen] = React.useState(false);
    const [treatmentFilterOptions, setTreatmentFilterOptions]
        = React.useState<TreatmentFilterOptions>({includedTypes: DEFAULT_FILTER_TYPES});
    const [locked, setLocked] = useState(true);

    const displayTreatments = useMemo(
        () => sortTreatments(filterTreatments(treatments, treatmentFilterOptions), getSortFunction(sort.name, sort.direction)),
        [treatments, sort, treatmentFilterOptions]);

    const handleFilterClick = () => setFilterDialogOpen(true);
    const handleFilterClose = () => setFilterDialogOpen(false);
    const handleFilterChange = (filterOptions: TreatmentFilterOptions) => {
        setTreatmentFilterOptions(filterOptions);
        handleFilterClose();
    };

    const handleTreatmentClick = (treatment: TreatmentListJson) => navigateToTreatmentEditor(treatment.type as TreatmentType, treatment);

    const navigateToTreatmentEditor = (treatmentType: TreatmentType, treatment?: TreatmentListJson) => {
        const locationState: TreatmentEditorLocationState | undefined = treatment ? {treatment} : undefined;
        history.push(
            {
                pathname: RouteService.expand(RouteService.TREATMENT_EDIT, {animalId: animal.id}),
                search: `?treatmentType=${treatmentType}&panonId=${animal.panonIdentifier.id}`,
                state: locationState
            }
        )
    }

    const {includedTypes} = treatmentFilterOptions;
    const filterActive = includedTypes !== DEFAULT_FILTER_TYPES;

    return (
        <Widget className="treatments-widget"
                displayName={
                    <>
                        Behandlungen
                        {locked ? <LockPerson/> : null}
                    </>}
                filterable
                filterActive={filterActive}
                sortableFields={Object.keys(sortFunctions)}
                onSortUpdate={(name: string, direction: SortDirection) => setSort({name, direction})}
                onFilterClick={handleFilterClick}
                cardMenuEntries={[
                    {actionName: "Neue Medikation", actionFunction: () => navigateToTreatmentEditor(TreatmentType.otherMedication)},
                    {actionName: "Gewicht erfassen", actionFunction: () => navigateToTreatmentEditor(TreatmentType.weighing)},
                    {actionName: "Bodyscore eintragen", actionFunction: () => navigateToTreatmentEditor(TreatmentType.bodyscore)},
                    {actionName: "Sonstige Behandlung erfassen", actionFunction: () => navigateToTreatmentEditor(TreatmentType.other)}
                ]}>
            <TreatmentFilterDialog open={filterDialogOpen}
                                   onClose={handleFilterClose}
                                   onChange={handleFilterChange}
                                   defaultTypes={DEFAULT_FILTER_TYPES}/>
            {locked ? (
                <Alert icon={false} severity="warning">
                    <Switch checked={!locked} onChange={ev => setLocked(!ev.target.checked)}/>
                    {' '}
                    Behandlungsdaten sind geschützt und nur für den Besitzer sichtbar.
                </Alert>
            ) : (
                <>
                    <Alert icon={false} severity="info">
                        <Switch checked={!locked} onChange={ev => setLocked(!ev.target.checked)}/>
                        {' '}
                        Behandlungsdaten werden beim Schließen der Tierdetails automatisch versperrt.
                    </Alert>
                    {!!includedTypes?.length && <FilterChips includedTypes={includedTypes}/>}
                    {displayTreatments.length
                        ? <PaginatedTreatmentList treatments={displayTreatments}
                                                  onTreatmentDelete={props.onTreatmentDelete}
                                                  onTreatmentClick={handleTreatmentClick}/>
                        : 'Keine Behandlungen vorhanden.'}
                </>
            )}
        </Widget>
    );
};

export default TreatmentList;
