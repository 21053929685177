import AnimalSearchResult from "../api/dtos/animal-search-result";
import {AnimalService, IAnimalService} from "./animal-service";
import {ListResult} from "../api/generated/lib-rest";
import {RegistrationJson, RegistrationsApi, RegistryApi} from "../api/generated/registry-rest";
import {RegistryDto} from "../api/generated/registry-service";
import ApiConfig from "../api/config/api-config";
import type {Sex} from "../api/generated/herd-animal";


export interface ISearchService {
    searchHerdAndPedigreeAnimals(searchTerm: string): Promise<AnimalSearchResult[]>;

    searchRegisteredAnimals(searchTerm: string): Promise<AnimalSearchResult[]>;

    filterBySex(sex: Sex, searchResults: AnimalSearchResult[]): AnimalSearchResult[];

    mergeByRegistry(searchResults: AnimalSearchResult[]): AnimalSearchResult[];

    /**
     * Combines the given search results by checking equality based on the panonIdentifier.
     * All entries from the baseSearchResults will be kept and search results from the searchResultsToMerge will only be taken into the combined result if no search result with
     * the same panonId exists in the baseSearchResults.
     *
     * @param baseSearchResults The search results to use as a base for merging additional search results with
     * @param searchResultsToMerge The search results to merge with the baseSearchResults
     */
    combineSearchResults(baseSearchResults: AnimalSearchResult[], searchResultsToMerge: AnimalSearchResult[]): AnimalSearchResult[]
}

export class SearchServiceImpl implements ISearchService {
    constructor(
        private readonly _registrationsApi: RegistrationsApi,
        private readonly _registryApi: RegistryApi,
        private readonly _animalService: IAnimalService) {
    }

    async searchHerdAndPedigreeAnimals(searchTerm: string): Promise<AnimalSearchResult[]> {
        //TODO exchange that for the search endpoint from backend
        const herd = await this._animalService.loadHerd("all");
        //TODO need real breeder and owner!
        return herd
            .filter(animal => (this._animalService.getAnimalFullName(animal)).toLowerCase().includes(searchTerm.toLowerCase()))
            .map(animal => new AnimalSearchResult(
                animal.panonIdentifier,
                animal.name,
                animal.herdCode,
                animal.dateOfBirth,
                animal.breed,
                animal.fiberColor,
                animal.secondaryColors,
                animal.eyeColor,
                animal.sex,
                "-",
                "-",
                []));
    }

    async searchRegisteredAnimals(searchTerm: string): Promise<AnimalSearchResult[]> {
        const normalizedSearchTerm = searchTerm.trim().toLowerCase();
        const registrations: ListResult<RegistrationJson> = await this._registrationsApi.findByName(normalizedSearchTerm);
        const registryIds: Set<string> = new Set<string>(registrations.items.map(r => r.registryId));
        const registryDetails
            = await Promise.all(Array.from(registryIds.values()).map(id => this._registryApi.details(id)));
        const registriesDetailsMap
            = new Map<string, RegistryDto>(registryDetails.map(singleResult => [singleResult.item.id, singleResult.item]));

        const searchResults = registrations.items.map(r => AnimalSearchResult.FromRegistration(r, registriesDetailsMap.get(r.registryId)));
        return this.mergeByRegistry(searchResults);
    }

    filterBySex(sex: Sex, searchResults: AnimalSearchResult[]): AnimalSearchResult[] {
        return searchResults.filter(a => {
            //There is still a quirks in our handling of enums when they're sent from backend to frontend and vice versa.
            // @ts-ignore
            return a.sex === sex || Sex[a.sex] === sex
        });
    }

    mergeByRegistry(searchResults: AnimalSearchResult[]): AnimalSearchResult[] {
        const resultsByPanonId: Map<string, AnimalSearchResult> = new Map<string, AnimalSearchResult>();
        searchResults.forEach(sr => {
            const mergedResult = resultsByPanonId.get(sr.panonId.id)
            if (mergedResult) {
                mergedResult.registries = mergedResult.registries.concat(sr.registries);
            } else {
                resultsByPanonId.set(sr.panonId.id, sr);
            }
        })
        return Array.from(resultsByPanonId.values());
    }

    /**
     * Combine Results for registered animals with herd and pedigree animals. If a search result for a registered animal
     * exists, the pedigree and herd result is dropped for that panon id.
     */
    combineSearchResults(baseSearchResults: AnimalSearchResult[], searchResultsToMerge: AnimalSearchResult[]): AnimalSearchResult[] {
        const registrationPanonIds = baseSearchResults.map(sr => sr.panonId.id);
        const filteredHerdAndPedigree = searchResultsToMerge.filter(r => !registrationPanonIds.includes(r.panonId.id));
        return baseSearchResults.concat(filteredHerdAndPedigree);

    }
}

const apiConfig = new ApiConfig();
const registrationsApi = new RegistrationsApi(apiConfig);
const registryApi = new RegistryApi(apiConfig);

export const SearchService = new SearchServiceImpl(registrationsApi, registryApi, AnimalService)