import {ApiConfigPort} from '../config/api-config-port';
import {BreederInfo} from './breeder-service';
import {BreederSearchResult} from './herd-breeder';
import {HttpService} from '../../services/http-service';
import {ListResult, Result, UpdateResult} from './lib-rest';

export class BreederAdminApi {
  config: ApiConfigPort;


 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async individual(): Promise<ListResult<BreederAdminController$BreederListJson> >  {
const url =  new URL('/breeders/list/individual', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async list(): Promise<ListResult<BreederAdminController$BreederListJson> >  {
const url =  new URL('/breeders/list', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async ofRegistry(registryId: string): Promise<ListResult<BreederAdminController$BreederListJson> >  {
const url =  new URL('/breeders/list/registry/' + registryId + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

}

export interface BreederAdminController$AddressJson {
  city: string;
  country: string;
  state: string;
  street: string;
  zipCode: string;
}

export interface BreederAdminController$BreederListJson {
  address: BreederAdminController$AddressJson;
  emailAddress: string;
  farmName: string;
  id: string;
  linkedAccountIds: string[];
  name: string;
  signupDate: any;
  vatNumber: string;
}

export class BreederApi {
  config: ApiConfigPort;


 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async getDetails(id: string): Promise<BreederController$BreederDetails>  {
const url =  new URL('/breeders/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async getInfo(): Promise<BreederInfo>  {
const url =  new URL('/breeders', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async getOwnDetails(): Promise<BreederController$BreederDetails>  {
const url =  new URL('/breeders/self', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async search(searchRequest: BreederSearchRequest): Promise<ListResult<BreederSearchResult> >  {
const url =  new URL('/breeders/search', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
if(searchRequest.email !== undefined){queryParamsList.push({name: 'email', value: searchRequest.email});}
if(searchRequest.farmName !== undefined){queryParamsList.push({name: 'farmName', value: searchRequest.farmName});}
if(searchRequest.herdCode !== undefined){queryParamsList.push({name: 'herdCode', value: searchRequest.herdCode});}
if(searchRequest.name !== undefined){queryParamsList.push({name: 'name', value: searchRequest.name});}
if(searchRequest.owner !== undefined){queryParamsList.push({name: 'owner', value: searchRequest.owner});}
if(searchRequest.website !== undefined){queryParamsList.push({name: 'website', value: searchRequest.website});}

    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async updateBreeder(updatedBreeder: BreederController$BreederDetails): Promise<UpdateResult>  {
const url =  new URL('/breeders', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(updatedBreeder))).data
  }

}

export interface BreederController$Address {
  city: string;
  country: string;
  state: string;
  street: string;
  zipCode: string;
}

export interface BreederController$BreederDetails {
  address: BreederController$Address;
  emailAddress: string;
  farmName: string;
  herdCode: string;
  id: string;
  owners: string[];
  phone: string;
  username: string;
  vatNumber: string;
  website: string;
}

export interface BreederSearchRequest {
  email: string;
  farmName: string;
  herdCode: string;
  name: string;
  owner: string;
  website: string;
}

export class BreederSignupApi {
  config: ApiConfigPort;


 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async linkAccount(linkAccountDto: LinkAccountDto): Promise<Result>  {
const url =  new URL('/breeders/link/account', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
if(linkAccountDto.accountName !== undefined){queryParamsList.push({name: 'accountName', value: linkAccountDto.accountName});}
if(linkAccountDto.breederName !== undefined){queryParamsList.push({name: 'breederName', value: linkAccountDto.breederName});}

    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().post(url.toString())).data
  }

 public  async listUnlinked(): Promise<ListResult<string> >  {
const url =  new URL('/breeders/unlinked', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString())).data
  }

 public  async signup(request: SignUpBreeder): Promise<Result>  {
const url =  new URL('/breeders/signup', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(request))).data
  }

}

export interface LinkAccountDto {
  accountName: string;
  breederName: string;
}

export interface SignUpBreeder {
  email: string;
  farm: SignUpFarm | null;
  herdCode: string;
  password: string;
  username: string;
}

export interface SignUpFarm {
  address: BreederController$Address;
  emailAddress: string;
  farmName: string;
  owners: string[];
  phone: string;
  vatNumber: string;
  website: string;
}

