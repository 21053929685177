import {BreederAdminApi, BreederAdminController$BreederListJson} from "../api/generated/breeder-controller";
import ApiConfig from "../api/config/api-config";
import {ListResult} from "../api/generated/lib-rest";

const items = <T>(result: Promise<ListResult<T>>): Promise<T[]> => result.then(x => x.items);

export interface IAdminBreederService {
    list(): Promise<BreederAdminController$BreederListJson[]>;
    listIndividuals(): Promise<BreederAdminController$BreederListJson[]>;
    listOfRegistry(registryId: string): Promise<BreederAdminController$BreederListJson[]>;
}

class AdminBreederServiceImpl implements IAdminBreederService {

    constructor(private readonly _breederAdminApi: BreederAdminApi) {
    }

    async list(): Promise<BreederAdminController$BreederListJson[]> {
        return await items(this._breederAdminApi.list());
    }

    async listIndividuals(): Promise<BreederAdminController$BreederListJson[]> {
        return await items(this._breederAdminApi.individual());
    }

    async listOfRegistry(registryId: string): Promise<BreederAdminController$BreederListJson[]> {
        return await items(this._breederAdminApi.ofRegistry(registryId));
    }

}

export const AdminBreederService: IAdminBreederService = new AdminBreederServiceImpl(
    new BreederAdminApi(new ApiConfig()));
