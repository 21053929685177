import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import {useSuspenseAnimal} from "../../Common/hooks/use-animal";
import {Document, Link, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import React, {PropsWithChildren} from "react";
import {ArrowBack, Downloading, FileDownload} from "@mui/icons-material";
import {PedigreeService} from "../../../services/pedigree-service";
import {PedigreeDto, PedigreeNodeDto} from "../../../api/dtos/pedigree-dto";
import {Button, Stack} from "@mui/material";
import RouteService from "../../../services/route-service";
import {useSuspenseQuery} from "@tanstack/react-query";

const styles = StyleSheet.create({
    page: {
        padding: '2cm',
        fontSize: 12,
        fontFamily: 'Times-Roman',
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subtitle: {
        textAlign: 'center',
        marginTop: '2mm',
        marginBottom: '4mm',
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: '6mm',
        marginBottom: '4mm',
    },
    footer: {
        fontSize: 12,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '0 2cm 1cm',
    },
    generationColumn: {
        width:'25%',
        display:'flex',
        justifyContent: 'space-around',
    },
    registriesSubtext: {
        fontSize: 10,
        color: '#666',
    },
});

const PdfTitle = ({children}: PropsWithChildren<{}>) => (
    <Text style={styles.title}>{children}</Text>
);

type PedigreeDocumentProps = {
    pedigree: PedigreeDto,
};

type Optional<T> = T | undefined | null;
const AnimalNode = ({node}: { node: Optional<PedigreeNodeDto>; }) => {
    if (!node) {
        return (
            <View>
                <Text>---</Text>
                <Text style={styles.registriesSubtext}>---</Text>
            </View>
        );
    }

    return (
        <View>
            <Text>{node.fullName?.trim() ?? '---'}</Text>
            <Text style={styles.registriesSubtext}>{node.registries?.length ? node.registries : 'nicht registriert'}</Text>
        </View>
    );
}
const Parents = ({node}: { node: Optional<{ dam: Optional<PedigreeNodeDto>, sire: Optional<PedigreeNodeDto>; }> }) => (
    <>
        <AnimalNode node={node?.sire}/>
        <AnimalNode node={node?.dam}/>
    </>
);

export const PedigreeDocument = ({pedigree}: PedigreeDocumentProps) => {
    const root = pedigree.root;
    const dam = root.dam;
    const sire = root.sire;

    return (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape">
                <PdfTitle>{root.fullName.trim()} &ndash; Stammbaum</PdfTitle>
                <Text style={styles.subtitle}>{root.registries.length ? root.registries : 'nicht registriert'}</Text>

                <View style={{flex:1, flexDirection: 'row'}}>
                    <View style={[styles.generationColumn, {width: '20%'}]}>
                        <Parents node={root} />
                    </View>
                    <View style={[styles.generationColumn, {width: '20%'}]}>
                        <Parents node={sire} />
                        <Parents node={dam} />
                    </View>
                    <View style={[styles.generationColumn, {width: '30%'}]}>
                        <Parents node={sire?.sire} />
                        <Parents node={sire?.dam} />
                        <Parents node={dam?.sire} />
                        <Parents node={dam?.dam} />
                    </View>
                    <View style={[styles.generationColumn, {width: '30%'}]}>
                        <Parents node={sire?.sire?.sire} />
                        <Parents node={sire?.sire?.dam} />
                        <Parents node={sire?.dam?.sire} />
                        <Parents node={sire?.dam?.dam} />
                        <Parents node={dam?.sire?.sire} />
                        <Parents node={dam?.sire?.dam} />
                        <Parents node={dam?.dam?.sire} />
                        <Parents node={dam?.dam?.dam} />
                    </View>
                </View>

                <View fixed style={styles.footer}>
                    <Text>
                        Quelle: <Link src="https://herds.alpacuna.com/">https://herds.alpacuna.com</Link>
                    </Text>
                </View>
            </Page>
        </Document>
    );
};

const PedigreePdf = ({match}: RouteComponentProps<{ panonId: string }>) => {
    const {panonId} = match.params;
    const history = useHistory();
    const {animal} = useSuspenseAnimal(panonId);

    const {data: pedigree} = useSuspenseQuery({
        queryKey: ['pedigree', animal?.id],
        queryFn: () => animal ? PedigreeService.loadPedigree(animal) : null,
    });

    if (!animal) {
        return null;
    }

    if (!pedigree) {
        return null;
    }

    const handleBackButton = () => history.push({
        pathname: RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId: animal.panonIdentifier.id})
    });

    const document = <PedigreeDocument pedigree={pedigree}/>;

    return (
        <Stack direction="column" spacing={2} margin={2}>
            <Stack direction="row" spacing={1}>
                <Button variant="outlined" startIcon={<ArrowBack/>} onClick={handleBackButton}>
                    Zurück
                </Button>
                <PDFDownloadLink document={document}
                                 fileName={`${animal.herdCode}_${animal.name}_pedigree.pdf`}>
                    {({loading}) => (
                        <Button startIcon={loading ? <Downloading/> : <FileDownload/>} variant="contained" disabled={loading}>
                            {loading ? 'PDF wird generiert' : 'Download PDF'}
                        </Button>
                    )}
                </PDFDownloadLink>
            </Stack>
            <PDFViewer width="100%" height="640">
                {document}
            </PDFViewer>
        </Stack>
    );
};

export default withRouter(PedigreePdf);
