import React, {FunctionComponent, PropsWithChildren, ReactNode, useMemo} from "react";
import registrationReviewService from "../../services/registration-review-service";
import {Box, Chip, Divider, List, ListItemAvatar, ListItemButton, ListItemText, Stack} from "@mui/material";
import Tabbed from "../Common/tabbed";
import {useNav} from "../Common/hooks/use-nav";
import RouteService from "../../services/route-service";
import {AnimalAvatar} from "../Common/animal-avatar";
import {DateTime} from "luxon";
import {RegistrationRequestController$PendingRequestJson} from "../../api/generated/registry-rest";
import {InfoBox} from "../Common/alerts";
import {useQuery} from "@tanstack/react-query";
import {LoadingFallback} from "../Common/loading-fallback";
import {LoadingAvatarListItem} from "../Common/loading-avatar-list-item";

const StatusChip = ({label, color}: {label:string; color: React.ComponentProps<typeof Chip>['color'];}) => <Chip label={label} color={color} size="small"/>;

const RegistrationRequestListItem: React.FC<PropsWithChildren<{
    application: RegistrationRequestController$PendingRequestJson;
    chip?: ReactNode;
}>> = ({application, chip, children}) => {
    const nav = useNav();

    return (
        <>
            <ListItemButton onClick={() => nav.push(RouteService.ANIMAL_DETAILS, {panonId: application.animal.id})}>
                <ListItemAvatar>
                    <AnimalAvatar animal={application.animal}/>
                </ListItemAvatar>
                <ListItemText primary={<>{application.animal.name} {chip}</>} secondary={children}/>
            </ListItemButton>
            <Divider variant="middle" component="li"/>
        </>
    );
};

const PendingRegistrationListItem: React.FC<{
    application: RegistrationRequestController$PendingRequestJson;
    statusChip?: boolean;
}> = ({application, statusChip}) => (
    <RegistrationRequestListItem application={application} chip={statusChip && <StatusChip label="aktiv" color="primary"/>}>
        <Stack>
            <Box>Bei Register: {application.registry.name}</Box>
        </Stack>
    </RegistrationRequestListItem>
);

const RejectedRegistrationListItem: FunctionComponent<{
    application: RegistrationRequestController$PendingRequestJson;
    statusChip?: boolean;
}> = ({application, statusChip}) => (
    <RegistrationRequestListItem application={application} chip={statusChip && <StatusChip label="abgelehnt" color="error"/>}>
        <Stack>
            <Box>Bei Register: {application.registry.name}</Box>
            <Box>Abgelehnt am: {DateTime.fromISO(application.rejectionTime).toLocaleString()}</Box>
            <Box>Zuchtwart-Kommentar: {application.rejectionComment}</Box>
        </Stack>
    </RegistrationRequestListItem>
);

const PendingRegistrations = () => {

    const {data: registrations = [], isPending} = useQuery({
        queryKey: ['pending-registrations'],
        queryFn: async () => registrationReviewService.loadPendingRequestsOfBreeder(),
    });

    const pending = useMemo(() => registrations.filter(r => !r.rejectionTime), [registrations]);
    const rejected = useMemo(() => registrations.filter(r => r.rejectionTime), [registrations]);

    if (isPending) {
        return (
            <Stack>
                <Tabbed>
                    <Tabbed.Tab label={`Aktiv`}></Tabbed.Tab>
                    <Tabbed.Tab label={`Abgelehnt`}></Tabbed.Tab>
                    <Tabbed.Tab label={`Alle`}></Tabbed.Tab>
                </Tabbed>
                <LoadingAvatarListItem/>
                <LoadingFallback/>
            </Stack>
        );
    }

    return (
        <Tabbed>
            <Tabbed.Tab label={`Aktiv ${pending.length}`}>
                {!pending.length && <InfoBox>Aktuell keine Registeranmeldungen vorhanden</InfoBox>}
                <List>{pending.map(r => <PendingRegistrationListItem key={r.id} application={r}/>)}</List>
            </Tabbed.Tab>
            <Tabbed.Tab label={`Abgelehnt ${rejected.length}`}>
                {!rejected.length && <InfoBox>Keine Registeranmeldungen abgelehnt</InfoBox>}
                <List>{rejected.map(r => <RejectedRegistrationListItem key={r.id} application={r}/>)}</List>
            </Tabbed.Tab>
            <Tabbed.Tab label={`Alle ${registrations.length}`}>
                {!registrations.length && <InfoBox>Keine Registeranmeldungen vorhanden</InfoBox>}
                <List>
                    {registrations.map(r => r.rejectionTime
                    ? <RejectedRegistrationListItem key={r.id} application={r} statusChip/>
                    : <PendingRegistrationListItem key={r.id} application={r} statusChip/>)}
                </List>
            </Tabbed.Tab>
        </Tabbed>
    );
};

export default PendingRegistrations;