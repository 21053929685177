import {DateTime} from "luxon";
import {RegistryDto} from "../generated/registry-service";
import {AlpacaBreed, AlpacaFiberColor, PanonIdentifier, Sex} from "../generated/herd-animal";
import {RegistrationJson} from "../generated/registry-rest";

export default class AnimalSearchResult {

    static FromRegistration(registration: RegistrationJson, registry: RegistryDto | undefined): AnimalSearchResult {
        const registries = registry ? [registry] : [];
        const phenotype = registration.phenotype;
        return new AnimalSearchResult(
            {id: registration.panonIdentifier, animalIdentifiers: []},
            registration.name,
            registration.herdCode,
            phenotype.dateOfBirth,
            phenotype.breed,
            phenotype.primaryColor,
            phenotype.secondaryColors,
            phenotype.eyeColor,
            phenotype.sex,
            "-",
            "-",
            registries)
    }

    constructor(
        public panonId: PanonIdentifier,
        public name: string,
        public herdCode: string,
        public dateOfBirth: DateTime,
        public breed: string | AlpacaBreed,
        public fiberColor: AlpacaFiberColor,
        public secondaryColors: AlpacaFiberColor[],
        public eyeColor: string,
        public sex: Sex,
        public breeder: string,
        public owner: string,
        public registries: RegistryDto[]) {
    }


}