import React, {useMemo} from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import Widget from "../../Common/widget";
import TreatmentType from "../../../api/dtos/treatment-type";
import PregnancyList from "./pregnancy-list";
import {PregnancyListJson, TreatmentListJson} from "../../../api/generated/medical-rest";
import {JsonAnimal} from "../../../api/generated/rest-dto";
import RouteService from "../../../services/route-service";
import {ParentTypeValues} from "../../../api/generated/herds-pedigree";
import {TreatmentEditorLocationState} from "../../../pages/treatment-editor";
import {PaginatedTreatmentList} from "../TreatmentList/paginated-treatment-list";
import {DateTime} from "luxon";
import {withErrorBoundary} from "react-error-boundary";
import {ErrorBox, InfoBox} from '../../Common/alerts';
import {useQuery} from "@tanstack/react-query";
import {PregnancyService} from "../../../services/pregnancy-service";
import {LoadingFallback} from "../../Common/loading-fallback";
import Divider from "@mui/material/Divider";
import Tabbed from "../../Common/tabbed";
import {Table, TableBody, TableCell, TableContainer, TableRow} from '@mui/material';
import {useBirthStats} from "./hooks/use-birth-stats";
import {LoadingSuspense} from "../../Common/loading-suspense";


interface PregnancyWidgetProps extends RouteComponentProps {
    pregnancies: PregnancyListJson[];
    treatments: TreatmentListJson[];
    animal: JsonAnimal;
    onDelete?:(pregnancyId: string) => void;
    onTreatmentDelete: (treatmentId: string) => void;
}


const getSuffix = (pregnancies: PregnancyListJson[]) => {
    const now = DateTime.now();
    for (const pregnancy of pregnancies) {
        const startDate: DateTime | undefined = pregnancy.startDate;
        const endDate: DateTime | undefined = pregnancy.actualEndDate;

        if (startDate && !endDate) {
            if (startDate.minus(now.toMillis()).toMillis() < 0) {
                return '(trächtig)';
            }
        }
    }
    for (const pregnancy of pregnancies) {
        const matingDate: DateTime | undefined = pregnancy.matingDate;
        const startDate: DateTime | undefined = pregnancy.startDate;
        const endDate: DateTime | undefined = pregnancy.actualEndDate;

        if (matingDate && !startDate && !endDate) {
            if (matingDate.minus(now.toMillis()).toMillis() < 0) {
                return '(gedeckt)';
            }
        }
    }
    return '';
}

const useActivePregnancies = (animal: JsonAnimal | undefined) => {
    const queryInfo = useQuery({
        queryKey: ['pregnancies', 'active', {panonId: animal?.panonIdentifier.id}],
        queryFn: async () => await PregnancyService.listActivePregnancies(animal!.panonIdentifier.id),
        enabled: !!animal,
    });

    return {
        ...queryInfo,
        activePregnancies: queryInfo.data,
    };
};

const BirthStats = ({dam}: { dam: JsonAnimal }) => {
    const {stats} = useBirthStats(dam);

    if (!stats.count) {
        return <InfoBox>Keine abgeschlossenen Trächtigkeiten vorhanden</InfoBox>;
    }

    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <TableRow hover>
                        <TableCell>Abgeschlossene Trächtigkeiten:</TableCell>
                        <TableCell>{stats.count}</TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Durchschnittliche Dauer:</TableCell>
                        <TableCell>{stats.average} Tag(e) {stats.min !== stats.average && stats.max !== stats.average ? <>({stats.min}&ndash;{stats.max})</> : null}</TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Letzte Trächtigkeit:</TableCell>
                        <TableCell>{stats.last} Tag(e)</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const PregnancyWidget: React.FC<PregnancyWidgetProps> = ({pregnancies, treatments, animal, onDelete, onTreatmentDelete}) => {

    const {activePregnancies = [], isLoading: activePregnanciesLoading} = useActivePregnancies(animal);
    const history = useHistory();

    const sortedTreatments = useMemo(
        () => [...treatments].sort((a: TreatmentListJson, b: TreatmentListJson) => b.timestamp.toMillis() - a.timestamp.toMillis()),
        [treatments]);

    const navigateToTreatmentEditor = (treatmentType: TreatmentType, treatment?: TreatmentListJson) => {
        const locationState: TreatmentEditorLocationState | undefined = treatment ? {treatment} : undefined;
        history.push(
            {
                pathname: RouteService.expand(RouteService.TREATMENT_EDIT, {animalId: animal.id}),
                search: `?treatmentType=${treatmentType}&panonId=${animal.panonIdentifier.id}`,
                state: locationState
            }
        )
    }

    const handleTreatmentClick = (treatment: TreatmentListJson) => navigateToTreatmentEditor(treatment.type as TreatmentType, treatment);

    const displayName = `Trächtigkeiten ${(getSuffix(pregnancies))}`;

    return (
        <Widget displayName={displayName} cardMenuEntries={[
            {
                actionName: "Deckung hinzufügen",
                actionFunction: () => history.push(RouteService.MATING_CREATE, {mother: animal})
            }, {
                actionName: "Trächtigkeit hinzufügen",
                actionFunction: () => history.push(RouteService.PREGNANCY_CREATE, {mother: animal})
            }, {
                actionName: "Spucktest hinzufügen",
                actionFunction: () => history.push(
                    {
                        pathname: RouteService.expand(RouteService.TREATMENT_EDIT, {animalId: animal.id}),
                        search: `?treatmentType=${TreatmentType.spittest}&panonId=${animal.panonIdentifier.id}`,
                    })
            }, {
                actionName: "Ultraschall hinzufügen",
                actionFunction: () => history.push(
                    {
                        pathname: RouteService.expand(RouteService.TREATMENT_EDIT, {animalId: animal.id}),
                        search: `?treatmentType=${TreatmentType.ultrasound}&panonId=${animal.panonIdentifier.id}`,
                    })
            }]}>

            {activePregnanciesLoading
                ? <LoadingFallback/>
                : <PregnancyList pregnancies={activePregnancies} baseParentKind={ParentTypeValues.DAM}/>}

            <Divider/>

            <Tabbed>
                <Tabbed.Tab label="Behandlungen">
                    <PaginatedTreatmentList treatments={sortedTreatments} onTreatmentDelete={onTreatmentDelete} onTreatmentClick={handleTreatmentClick} />
                </Tabbed.Tab>
                <Tabbed.Tab label="Historie">
                    <PregnancyList pregnancies={pregnancies} baseParentKind={ParentTypeValues.DAM} onDelete={onDelete}/>
                </Tabbed.Tab>
                <Tabbed.Tab label="Statistik">
                    <LoadingSuspense>
                        <BirthStats dam={animal}/>
                    </LoadingSuspense>
                </Tabbed.Tab>
            </Tabbed>

        </Widget>
    );
};

export default withRouter(
    withErrorBoundary(
        PregnancyWidget,
        {
            fallback: (
                <Widget displayName="Trächtigkeiten">
                    <ErrorBox>Fehler beim Rendern der Trächtigkeiten!</ErrorBox>
                </Widget>
            ),
        }
    )
);