// @flow
import React, {FunctionComponent, useEffect, useState} from 'react';
import {
    Avatar,
    IconButton,
    Stack,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useLocation, useParams} from "react-router-dom";
import RegistrationReviewService from "../../services/registration-review-service";
import {DateTime} from "luxon";
import {JsonAnimal} from "../../api/generated/rest-dto";
import ReviewSimilaritiesComparisonDto from "../../api/dtos/review-similarities-comparison-dto";
import {AnimalService} from "../../services/animal-service";
import {useTranslation} from "react-i18next";
import {Widget} from "../Common";
import FlexStack from '../Common/flex-stack';
import {ArrowBack} from "@mui/icons-material";
import {useNav} from "../Common/hooks/use-nav";

interface SimilarityComparisonPathParams {
    reviewedAnimalPanonId: string,
    similarAnimalPanonId: string
}

interface SimilarityComparisonStateParams {
    reviewedAnimal: JsonAnimal,
    similarAnimal: JsonAnimal
}

const useStringRenderer = () => {
    const {t} = useTranslation();

    const renderAsString = (object: unknown, translationHint?: string): string => {
        if (!object) {
            return '';
        }
        if (DateTime.isDateTime(object)) {
            return (object as DateTime).toLocaleString()
        }

        switch (translationHint) {
            case 'sex': return t('sex.' + object);
            case 'breed': return t('breed.' + object);
            case 'color': return t('color.' + object);
            case 'eyecolor': return t('eyecolor.' + object);
            default: return object.toString();
        }
    }

    return renderAsString;
};

const SimilarityComparison: FunctionComponent = () => {
    const pathParams = useParams<SimilarityComparisonPathParams>();
    const locationState = useLocation<SimilarityComparisonStateParams>().state;
    let reviewedAnimal: JsonAnimal | null = null;
    let similarAnimal: JsonAnimal | null = null;
    if (locationState) {
        reviewedAnimal = locationState.reviewedAnimal;
        similarAnimal = locationState.similarAnimal;
    }

    var {history} = useNav();

    const [similarities, setSimilarities] = useState<ReviewSimilaritiesComparisonDto>();

    const initialize = async () => {
        if (!reviewedAnimal) {
            reviewedAnimal = await AnimalService.loadJsonAnimalByPanonId(pathParams.reviewedAnimalPanonId);
        }
        if (!similarAnimal) {
            similarAnimal = await AnimalService.loadJsonAnimalByPanonId(pathParams.similarAnimalPanonId);
        }
        setSimilarities(await RegistrationReviewService.loadSimilarityComparison(reviewedAnimal, similarAnimal));
    }

    const renderAsString = useStringRenderer();

    useEffect(() => {
        void initialize();
    }, [pathParams.reviewedAnimalPanonId, pathParams.similarAnimalPanonId]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!similarities) {
        return <Stack></Stack>;
    }

    return (
        <FlexStack spacing={1}>
            <Widget displayName={
                <>
                    <IconButton aria-label="back" onClick={() => history.goBack()}><ArrowBack/></IconButton>
                    Ähnlichkeiten
                </>
            }>
                <TableContainer>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell align="center">
                                <Avatar variant="square" sx={{height: 100, width: 100}} src={similarities.avatarUrlReviewAnimal}/>
                            </TableCell>
                            <TableCell align="center">
                                <Avatar variant="square" sx={{height: 100, width: 100}} src={similarities.avatarUrlSimilarAnimal}/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell/>
                            <TableCell align="center">{reviewedAnimal?.name}</TableCell>
                            <TableCell align="center">{similarAnimal?.name}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {similarities.comparisonPairs.map(s => {
                            let fw = "regular";
                            let color = "";
                            if (s.isEqual()) {
                                fw = "bold";
                                color = "error";
                            }
                            return (
                                <TableRow key={s.name}>
                                    <TableCell>{s.name}</TableCell>
                                    <TableCell align="center">
                                        <Typography fontWeight={fw} color={color}
                                                    variant="subtitle1">{renderAsString(s.firstValue, s.translationHint)}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography fontWeight={fw} color={color}
                                                    variant="subtitle1">{renderAsString(s.secondValue, s.translationHint)}</Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </TableContainer>
            </Widget>
        </FlexStack>
    );
};

export default SimilarityComparison
