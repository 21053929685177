import {CreateTreatmentJson, TreatmentApi, TreatmentDetailJson, TreatmentListJson} from "../api/generated/medical-rest";
import ApiConfig from "../api/config/api-config";
import {Interval, TreatmentFilter} from "../api/generated/herd-medical";
import {CreateResult, DeleteResult, UpdateResult} from "../api/generated/lib-rest";
import TreatmentType from "../api/dtos/treatment-type";

let treatmentApi = new TreatmentApi(new ApiConfig());

const EMPTY_TREATMENT_LIST_JSON: TreatmentListJson = {
    animalId: "",
    dose: 0,
    drug: "",
    id: "",
    note: "",
    timestamp: undefined,
    unit: "",
    value: 0,
    type: TreatmentType.otherMedication,
    private: false,
    isPrivate: false,
}

const loadTreatmentsForAnimalId = async (animalId: string): Promise<TreatmentListJson[]> => {
    let treatmentFilter: TreatmentFilter = {
        animalId: animalId,
        interval: {
            start: undefined,
            end: undefined
        },
        type: undefined as unknown as string
    };
    return await loadTreatments(treatmentFilter);
}

const loadTreatments = async (treatmentFilter: TreatmentFilter): Promise<TreatmentListJson[]> => {
    let filterInterval = treatmentFilter.interval;
    let interval: Interval = {
        start: filterInterval?.start?.toUTC().toISO(),
        end: filterInterval?.end?.toUTC().toISO()
    }
    let filter: TreatmentFilter = {...treatmentFilter, interval};
    return (await treatmentApi.list(filter)).items;
}

const updateTreatment = async (treatment: TreatmentListJson): Promise<UpdateResult> => {
    const treatmentToUpdate: TreatmentDetailJson = {...treatment, timestamp: treatment.timestamp.toUTC()}
    return await treatmentApi.update(treatment.id, treatmentToUpdate);
}

const addTreatment = async (treatment: CreateTreatmentJson): Promise<CreateResult> => {
    const createTreatment: CreateTreatmentJson = {...treatment, timestamp: treatment.timestamp.toUTC()}
    return await treatmentApi.create(createTreatment);
}

const deleteTreatment = async (treatmentId: string): Promise<DeleteResult> => {
    return await treatmentApi.delete(treatmentId);
}

const TreatmentService = {
    EMPTY_TREATMENT_LIST_JSON,
    loadTreatmentsForAnimalId,
    loadTreatments,
    updateTreatment,
    addTreatment,
    deleteTreatment
};

export default TreatmentService;