// @flow
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Container,
    Fab,
    IconButton,
    Link,
    Stack,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {SearchField} from '../components/Common';
import {useHistory} from 'react-router-dom';
import RouteService from '../services/route-service';
import EditIcon from '@mui/icons-material/Edit';
import AuthService from '../services/auth-service';
import {PANON_ADMIN} from '../components/Permissions/Roles';
import ConfirmDialog from "../components/Common/confirm-dialog";
import {HelpService} from "../services/help-service";
import {useGlobalSnackbarStore} from "../stores/global-snackbar-store";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

// Interface for help articles
interface HelpArticle {
    id: string;
    title: string;
    summary: string;
    fullUrl: string;
    videoUrl?: string; // Optional video URL
}

// HelpPage Component
const HelpPage: React.FC = () => {
    const {t} = useTranslation();
    const [selectedArticle, setSelectedArticle] = useState<string | null>(null); // Selected article
    const [searchTerm, setSearchTerm] = useState("");
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [articleToDelete, setArticleToDelete] = useState<string | null>(null);
    const history = useHistory();
    const queryClient = useQueryClient();
    const {error, success} = useGlobalSnackbarStore((state) => state);

    const isAdmin = AuthService.hasRole([PANON_ADMIN]);

    const {data: articles} = useQuery({
        queryKey: ["helpArticles", searchTerm],
        queryFn: async () => HelpService.search(searchTerm)
    })

    const deleteArticleMutation = useMutation({
        mutationFn: async (articleId: string) => HelpService.delete(articleId),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['helpArticles']});
            success("Erfolgreich gelöscht!")
        },
        onError: (e) => error("Löschen ist fehlgeschlagen: " + e),
    });

    const findSuggestions = async (searchTerm: string): Promise<HelpArticle[]> => {
        setSearchTerm(searchTerm);
        return []; // We don't show suggestions in this search. Instead, we only update the search term for react query.
    };

    // Function to toggle the expansion of a help article
    const toggleArticle = (id: string) => {
        setSelectedArticle(selectedArticle === id ? null : id);
    };

    // Function to navigate to the edit page of a help article
    const handleEditArticle = (id: string) => {
        history.push({pathname: RouteService.expand(RouteService.EDIT_HELP_ARTICLE, {articleId: id})});
    };

    // Function to navigate to the add new help article page
    const handleAddArticle = () => {
        history.push({pathname: RouteService.ADD_HELP_ARTICLE});
    };

    // Function to handle deleting an article
    const handleDeleteArticle = (id: string) => {
        setArticleToDelete(id);
        setConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        if (articleToDelete) {
            deleteArticleMutation.mutate(articleToDelete);
        }
        setConfirmDialogOpen(false);
        setArticleToDelete(null);
    };

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
        setArticleToDelete(null);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>{t('helpPage.title')}</Typography>
            <SearchField
                renderOption={(a: HelpArticle) => a.title}
                findSuggestions={findSuggestions}
                value={null}
                showSearchIcon={true}
                label={t('helpPage.searchLabel')}
            />

            <Stack spacing={3} className="help-articles">
                <Stack>
                    {articles?.map(article => (
                        <Accordion key={article.id} expanded={selectedArticle === article.id}
                                   onChange={() => toggleArticle(article.id)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Stack direction="row" width="100%">
                                    <Typography
                                        variant="h6">{t('helpPage.articleTitle', {title: article.title})}</Typography>
                                    {isAdmin && (
                                        <>
                                            <IconButton style={{marginLeft: "auto"}} onClick={(e) => {
                                                e.stopPropagation();
                                                handleEditArticle(article.id);
                                            }}>
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteArticle(article.id);
                                            }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </>
                                    )}
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {article.summary.split('\n\n').map(summary =>
                                        <p>{t('helpPage.articleSummary', {summary})}</p>)}
                                </Typography>
                                {article.fullUrl && (
                                    <Link href={article.fullUrl} target="_blank" rel="noopener noreferrer">
                                        {t('helpPage.readMore')}
                                    </Link>
                                )}
                                {article.videoUrl && (
                                    <Link href={article.videoUrl} target="_blank" rel="noopener noreferrer"
                                          style={{display: 'block', marginTop: '8px'}}>
                                        {t('helpPage.watchVideo')}
                                    </Link>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Stack>
                <Stack direction="row" alignItems="baseline">
                    <Typography>Deine Frage war nicht dabei? Dann sende uns eine</Typography>
                    <Button size="large" variant="text" onClick={(e) => {
                        window.location.href = "mailto:support@alpacuna.com"
                        e.preventDefault();
                    }}>Email</Button>
                </Stack>
            </Stack>

            {isAdmin && (
                <Fab color="primary" aria-label="add" style={{position: 'fixed', bottom: 16, right: 16}}
                     onClick={handleAddArticle}>
                    <AddIcon/>
                </Fab>
            )}

            <ConfirmDialog
                title={t('helpPage.deleteTitle')}
                cancelButtonText={t('helpPage.cancelButtonText')}
                okButtonText={t('helpPage.okButtonText')}
                onClose={handleCancelDelete}
                onOk={handleConfirmDelete}
                open={confirmDialogOpen}
            />
        </Container>
    );
};

export default HelpPage;
