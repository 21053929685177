import React, {useState} from "react";
import {Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow} from "@mui/material";

const renderCell = <T, >(col: ColumnDefinition<T>, row: T) => {
    const {accessor, render = x => x} = col;
    const value = typeof accessor === 'function' ? accessor(row) : row[accessor];
    return render(value);
};

export type ColumnDefinition<T> = {
    id: string,
    accessor: keyof T | (<R extends keyof T>(row: T) => T[R]),
    header?: string,
    render?: <R extends keyof T>(value: T[R]) => React.ReactNode,
};

export const DataTable = <T extends Record<string, any>>(props: { data: T[], columns: ColumnDefinition<T>[] }) => {
    const {data, columns} = props;

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const handleChangePage = (_event: React.MouseEvent | null, page: number) => setPage(page);
    const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const dataPage = data.slice(page * pageSize, page * pageSize + pageSize);

    return (
        <Table>
            {columns.some(c => c.header) && (
                <TableHead>
                    {columns.map(col => <TableCell key={col.id}>{col.header}</TableCell>)}
                </TableHead>
            )}
            <TableBody>
                {dataPage.map((row) => (
                    <TableRow>
                        {columns.map(col => (
                            <TableCell key={col.id}>
                                {renderCell(col, row)}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        colSpan={columns.length}
                        count={data.length}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        onRowsPerPageChange={handleChangePageSize}/>
                </TableRow>
            </TableFooter>
        </Table>
    );
};