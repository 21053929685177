import React, {FC, useState} from 'react';
import Widget from "../Common/widget";
import {Link as RouterLink, RouteComponentProps, withRouter} from "react-router-dom";
import TabularTextView from './Common/tabular-text-view';
import AnimalBaseDataForm from "../AnimalCreate/animal-base-data-form";
import {useGlobalSnackbarStore} from "../../stores/global-snackbar-store";
import {AnimalBaseData} from "../../api/dtos/animal-base-data";
import {useTranslation} from 'react-i18next';
import {transformAnimalBaseData} from "../../util/animal-util";
import {useActivateAnimal, useDeactivateAnimal} from "./hooks/mutations";
import {Box, Button, CircularProgress, IconButton, Stack, styled} from "@mui/material";
import {JsonAnimal} from "../../api/generated/rest-dto";
import {useAvatarUpload} from "../../hooks/use-avatars";
import {AnimalAvatar} from "../Common/animal-avatar";
import {Edit, UploadFile} from "@mui/icons-material";
import ChipsList from "../chips-list";
import RouteService from "../../services/route-service";
import {AnimalRecordStatusValues} from "../../api/generated/herd-animal";
import {useNav} from "../Common/hooks/use-nav";


interface BaseDataProps extends RouteComponentProps {
    initialBaseData: AnimalBaseData
    tags?: string[],
    animalId?: string,
    editable?: boolean
    activatable?: boolean,
    deactivatable?: boolean,
    onSubmit?: (animalBaseData: AnimalBaseData) => void
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ImageUpload = ({animal}: { animal: Pick<JsonAnimal, 'id' | 'name'> }) => {
    const {upload, isUploading} = useAvatarUpload(animal);

    return (
        <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            disabled={isUploading}
            startIcon={isUploading ? <CircularProgress size="1em"/> : <UploadFile/>}
        >
            {isUploading ? 'Laden ...' : 'Profilbild hochladen'}
            <VisuallyHiddenInput
                type="file"
                accept=".jpg,.jpeg,.gif,.png,.webp,image/jpeg,image/gif,image/png,image/webp,image/*"
                onChange={(event) => event.target.files && upload({
                    file: event.target.files[0],
                })}/>
        </Button>
    );
};

const BaseData: FC<BaseDataProps> = ({
                                         initialBaseData,
                                         tags = [],
                                         editable = true,
                                         activatable = false,
                                         deactivatable = false,
                                         onSubmit,
                                         animalId = 'unknown'
                                     }) => {
    const {name, herdCode} = initialBaseData;

    const {success} = useGlobalSnackbarStore((state) => state);

    const [animalBaseData, setAnimalBaseData] = useState(initialBaseData)
    const [editOpen, setEditOpen] = useState(false)
    const {t} = useTranslation();
    const {deactivateAnimal} = useDeactivateAnimal();
    const {activateAnimal} = useActivateAnimal();

    const nav = useNav();
    const handleTagClick = (tag: string) => nav.push(RouteService.HERD_GROUP, {
        groupId: tag,
        recordStatus: AnimalRecordStatusValues.HERD
    });

    const handleEditFormSubmit = async (update: AnimalBaseData) => {
        onSubmit?.(update);
        setAnimalBaseData(update);
        setEditOpen(false);
    };

    const handleAnimalActivate = async () =>
        activateAnimal(animalBaseData.panonIdentifier, {
            onSuccess: () => success("Tier wurde reaktiviert!"),
        });

    const handleAnimalDeactivate = async () =>
        deactivateAnimal(animalBaseData.panonIdentifier, {
            onSuccess: () => success("Tier wurde deaktiviert!"),
        });

    const editTagRoute = RouteService.expand(RouteService.TAGS_EDIT, {panonId: animalBaseData.panonIdentifier.id});
    const editTags = () => nav.push(editTagRoute);

    const buildCardMenuEntries = () => {
        const entries = [];
        if (editable) {
            entries.push({actionName: "Bearbeiten", actionFunction: () => setEditOpen(true)});
        }
        if (deactivatable) {
            entries.push({actionName: "Deaktivieren", actionFunction: handleAnimalDeactivate});
        }
        if (activatable) {
            entries.push({actionName: 'Aktivieren', actionFunction: handleAnimalActivate});
        }
        entries.push({actionName: 'Tags bearbeiten', actionFunction: editTags});
        return entries;
    }

    const animal = {id: animalId, name: initialBaseData.name};
    return (
        <Widget displayName={<Stack direction="row">
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box sx={{mr: 1}}>
                    <AnimalAvatar animal={animal}/>
                </Box>
                Stammdaten von {herdCode} {name}
            </Box>
        </Stack>}
                editable={editable || deactivatable}
                cardMenuEntries={buildCardMenuEntries()}>
            <Stack spacing={2}>
                {tags.length
                    ? (
                        <Stack direction="row">
                            <ChipsList listItems={tags} onItemClick={handleTagClick}/>
                            <IconButton onClick={editTags}>
                                <Edit/>
                            </IconButton>
                        </Stack>
                    )
                    : <Box>Noch keine Tags. <RouterLink to={{pathname: editTagRoute}}>Tags hinzufügen</RouterLink></Box>}

                {editOpen
                    ? <AnimalBaseDataForm onSubmitClicked={handleEditFormSubmit}
                                          onCancelClicked={() => setEditOpen(false)}
                                          initialData={animalBaseData}
                                          allowIdUpdate={false}/>
                    : <TabularTextView displayPairs={transformAnimalBaseData(t, animalBaseData)}/>
                }
            </Stack>
            {editable && <ImageUpload animal={animal}/>}
        </Widget>
    );
};

export default withRouter(BaseData);
