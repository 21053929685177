// @flow

import {AnimalBaseData} from "../api/dtos/animal-base-data";
import {JsonAnimal} from "../api/generated/rest-dto";

export const transformAnimalBaseData = (t: (id: string) => string, animal: AnimalBaseData): Map<string, any> => {
    return new Map([
        ["Herdkürzel", animal.herdCode],
        ["Name", animal.name],
        ["Geschlecht", t('sex.' + animal.sex)],
        ["Farbe", t('color.' + animal.fiberColor)],
        ['Zusatzfarben', animal.secondaryColors?.map(c => t('color.' + c)).join(', ') ?? ''],
        ['Augenfarbe', animal.eyeColor ? t('eyecolor.' + animal.eyeColor) : ''],
        ["Geburtstag", animal.dateOfBirth?.toLocaleString() ?? "n.a."],
        ["Rasse", t('breed.' + animal.breed)],
    ]);

};

export const transformFromJsonAnimal = (t: (id: string) => string, jsonAnimal: JsonAnimal): Map<string, any> => {
    return new Map([
        ["Herdkürzel", jsonAnimal.herdCode],
        ["Name", jsonAnimal.name],
        ["Geschlecht", t('sex.' + jsonAnimal.sex)],
        ["Farbe", t('color.' + jsonAnimal.fiberColor)],
        ['Zusatzfarben', jsonAnimal.secondaryColors?.map(c => t('color.' + c)).join(', ') ?? ''],
        ['Augenfarbe', jsonAnimal.eyeColor ? t('eyecolor.' + jsonAnimal.eyeColor) : ''],
        ["Geburtstag", jsonAnimal.dateOfBirth?.toLocaleString() ?? "n.a."],
        ["Rasse", t('breed.' + jsonAnimal.breed)],
    ]);

};
