// @flow
import React, {FunctionComponent} from 'react';
import FlexCard from "./flex-card";
import {RegistrationJson} from "../../api/generated/registry-rest";
import AnimalRegistrationsList from "../Registry/animal-registrations-list";
import {InfoBox} from "../Common/alerts";

interface ExistingRegistrationCardProps {
    existingRegistrations: RegistrationJson[]
}

const ExistingRegistrationsCard: FunctionComponent<ExistingRegistrationCardProps> = ({existingRegistrations}) => {
    return (
        <FlexCard title="Bereits registriert bei">
            {existingRegistrations.length
                ? <AnimalRegistrationsList animalRegistrations={existingRegistrations} pendingRegistrations={[]} withDivider={true} />
                : <InfoBox>Tier ist noch bei keinem Register registriert</InfoBox>}
        </FlexCard>
    );
};

export default ExistingRegistrationsCard