import ReviewSimilaritiesComparisonDto, {ComparisonPair} from "../api/dtos/review-similarities-comparison-dto";
import {
    RegistrationRequestApi,
    RegistrationRequestController$PendingRequestJson,
    RequestRegistrationApi,
    RequestRegistrationController$AnimalSimilarityJson
} from "../api/generated/registry-rest";
import ApiConfig from "../api/config/api-config";
import {RegistryService} from "./registry-service";
import {JsonAnimal} from "../api/generated/rest-dto";
import {RegistrationService} from "./registration-service";
import {BreederService} from "./breeder-service";

const apiConfig = new ApiConfig();
const registrationRequestApi = new RegistrationRequestApi(apiConfig);
const requestRegistrationApi = new RequestRegistrationApi(apiConfig);


const loadPendingRequestsOfBreeder = async (includeRejected = true): Promise<RegistrationRequestController$PendingRequestJson[]> => {
    const {id: breederId} = await BreederService.info();
    const {items} = await registrationRequestApi.list(undefined, breederId, undefined, includeRejected);
    return items;
}

const loadReviewRequestsByWarden = async (includeRejected = true): Promise<RegistrationRequestController$PendingRequestJson[]> => {
    const registries = await RegistryService.findAllWhereBreederIsWarden();
    const listResults = await Promise.all(registries.map(r => registrationRequestApi.list(r.id, undefined, undefined, includeRejected)));
    return listResults.flatMap(lr => lr.items);
}

const addPairIfValuesAreEqual = (pairsArray: ComparisonPair[], displayName: string, reviewedValue: any, similarValue: any, translationHint?: string) => {
    if (reviewedValue === similarValue) {
        pairsArray.push(new ComparisonPair(displayName, reviewedValue, similarValue, translationHint));
    }
}

const loadSimilarityComparison = async (reviewedAnimal: JsonAnimal, similarAnimal: JsonAnimal): Promise<ReviewSimilaritiesComparisonDto> => {
    const compPairs: ComparisonPair[] = [];
    addPairIfValuesAreEqual(compPairs, "Herdkürzel", reviewedAnimal.herdCode, similarAnimal.herdCode);
    addPairIfValuesAreEqual(compPairs, "Name", reviewedAnimal.name, similarAnimal.name);
    addPairIfValuesAreEqual(compPairs, "Geschlecht", reviewedAnimal.sex, similarAnimal.sex, 'sex');
    addPairIfValuesAreEqual(compPairs, "Geburtsdatum", reviewedAnimal.dateOfBirth.toLocaleString(), similarAnimal.dateOfBirth.toLocaleString());
    addPairIfValuesAreEqual(compPairs, "Farbe", reviewedAnimal.fiberColor, similarAnimal.fiberColor, 'color');
    addPairIfValuesAreEqual(compPairs, "Augenfabe", reviewedAnimal.eyeColor, similarAnimal.eyeColor, 'eyecolor');
    addPairIfValuesAreEqual(compPairs, "Rasse", reviewedAnimal.breed, similarAnimal.breed, 'breed');
    addPairIfValuesAreEqual(compPairs,
        "Chip",
        reviewedAnimal.panonIdentifier.animalIdentifiers[0]?.value,
        similarAnimal.panonIdentifier.animalIdentifiers[0]?.value);

    return {
        comparisonPairs: compPairs,
        avatarUrlReviewAnimal: "",
        avatarUrlSimilarAnimal: "",
    };
}

const rejectReview = async (requestId: string, comment: string) =>
    await registrationRequestApi.reject(requestId, comment);

const acceptReview = async (requestId: string) => {
    await requestRegistrationApi.acceptRequest(requestId);
}

const findSimilarAnimals = async (reviewRequestId: string): Promise<RequestRegistrationController$AnimalSimilarityJson[]> => {
    return (await requestRegistrationApi.findSimilarAnimals(reviewRequestId)).items;
}

const findRegisteredSimilarAnimals = async (reviewRequestId: string): Promise<RequestRegistrationController$AnimalSimilarityJson[]> => {
    const similarAnimals = await findSimilarAnimals(reviewRequestId);
    const filtered = [];
    for (const sa of similarAnimals) {
        if ((await RegistrationService.findRegistrationsByPanonId(sa.panonIdentifier)).length > 0) {
            filtered.push(sa);
        }
    }
    return filtered;

}

const RegistrationReviewService = {
    loadPendingRequestsOfBreeder,
    loadReviewRequestsByWarden,
    loadSimilarityComparison,
    findSimilarAnimals,
    findRegisteredSimilarAnimals,
    rejectReview,
    acceptReview
};

export default RegistrationReviewService;