import {
    BreederApi,
    BreederController$BreederDetails,
    BreederSignupApi,
    LinkAccountDto
} from "../api/generated/breeder-controller";
import ApiConfig from "../api/config/api-config";
import {BreederInfo} from "../api/generated/breeder-service";
import {BreederSearchResult} from "../api/generated/herd-breeder";
import {Result, UpdateResult} from "../api/generated/lib-rest";
import {BreederRegistrationsApi, BreederRegistrationsController$JsonRegistration} from "../api/generated/registry-rest";
import {FarmData} from "../api/dtos/breeder-update";


export interface IBreederService {
    search(searchTerm: string): Promise<BreederSearchResult[]>;
    info(): Promise<BreederInfo>;
    ownDetails(): Promise<BreederController$BreederDetails>
    getDetails(breederId: string): Promise<BreederController$BreederDetails>;
    listUnlinked(): Promise<string[]>;
    linkAccounts(linkAccountDto: LinkAccountDto): Promise<Result>;
    registeredAnimals(ownerId: string): Promise<BreederRegistrationsController$JsonRegistration[]>;
    updateBreeder(farmData: FarmData): Promise<UpdateResult>;
}

class BreederServiceImpl implements IBreederService {

    private _breederSignupApi: BreederSignupApi
    private _breederApi: BreederApi
    private _breederRegistrationsApi: BreederRegistrationsApi

    constructor(breederApi: BreederApi, breederSignupApi: BreederSignupApi, breederRegistrationsApi: BreederRegistrationsApi) {
        this._breederApi = breederApi;
        this._breederSignupApi = breederSignupApi;
        this._breederRegistrationsApi = breederRegistrationsApi;
    }

    async ownDetails(): Promise<BreederController$BreederDetails> {
       return this._breederApi.getOwnDetails();
    }



    async getDetails(breederId: string): Promise<BreederController$BreederDetails> {
        return await this._breederApi.getDetails(breederId)
    }

    async search(searchTerm: string): Promise<BreederSearchResult[]> {
        const request = {
            name: searchTerm,
            email: searchTerm,
            herdCode: searchTerm,
            farmName: searchTerm,
            owner: searchTerm,
            website: searchTerm

        };
        return (await this._breederApi.search(request)).items;
    }

    async info(): Promise<BreederInfo> {
        return await this._breederApi.getInfo();
    }

    async listUnlinked(): Promise<string[]> {
        return (await this._breederSignupApi.listUnlinked()).items;
    }

    async linkAccounts(linkAccountDto: LinkAccountDto): Promise<Result> {
        return this._breederSignupApi.linkAccount(linkAccountDto);
    }

    async registeredAnimals(ownerId: string): Promise<BreederRegistrationsController$JsonRegistration[]> {
        return (await this._breederRegistrationsApi.registeredAnimals(ownerId)).items;
    }

    async updateBreeder(farmData: FarmData): Promise<UpdateResult> {
        const updatedBreeder: BreederController$BreederDetails = {
            ...farmData,
            owners: farmData.owners.map(p => p.name),
        };
        return (await this._breederApi.updateBreeder(updatedBreeder));
    }
}


export const BreederService: IBreederService = new BreederServiceImpl(
    new BreederApi(new ApiConfig()),
    new BreederSignupApi(new ApiConfig()),
    new BreederRegistrationsApi(new ApiConfig()));
