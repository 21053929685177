import {DateTime} from "luxon";
import {AnimalApi, AnimalController$CreateAnimalResult} from "../api/generated/animal-controller";
import ApiConfig from "../api/config/api-config";
import {CreateAnimalDto, JsonAnimal} from "../api/generated/rest-dto";
import {UpdateResult} from "../api/generated/lib-rest";
import {AnimalIdentifier, PanonIdentifier} from "../api/generated/herd-animal";
import {animalIdentifiersAreEqual} from "../util/animal-identification-util";
import {AnimalBaseData} from "../api/dtos/animal-base-data";

let animalApi = new AnimalApi(new ApiConfig());

export interface IAnimalService {
    loadHerd(tag: string): Promise<JsonAnimal[]>;

    createForHerd(animal: CreateAnimalDto): Promise<AnimalController$CreateAnimalResult>;

    createForPedigree(animal: CreateAnimalDto): Promise<AnimalController$CreateAnimalResult>;

    loadJsonAnimalById(id: string): Promise<JsonAnimal>;

    loadJsonAnimalByPanonId(panonId: string): Promise<JsonAnimal>;

    getAnimalFullName(animal: Pick<JsonAnimal, 'herdCode' | 'name'> | undefined): string;

    loadJsonAnimalsByPanonIds(panonIds: string[]): Promise<JsonAnimal[]>

    updateBaseData(current: JsonAnimal, updatedData: AnimalBaseData): Promise<void>

    hasAnimalIdentifier(animal: JsonAnimal, identifier: AnimalIdentifier): boolean

    deleteAnimalIdentifier(animal: JsonAnimal, ai: AnimalIdentifier): Promise<void>;

    updateAnimalIdentifier(animal: JsonAnimal, original: AnimalIdentifier, updated: AnimalIdentifier): Promise<void>;

    addAnimalIdentifier(animal: JsonAnimal, newIdentifier: AnimalIdentifier): Promise<void>;

    activateAnimal(panonId: PanonIdentifier): Promise<void>;

    deactivateAnimal(panonId: PanonIdentifier): Promise<void>;
}

class AnimalServiceImpl implements IAnimalService {
    constructor(private readonly _animalApi: AnimalApi) {
    }

    async updateAnimalIdentifier(animal: JsonAnimal, original: AnimalIdentifier, updated: AnimalIdentifier): Promise<void> {
        if (this.hasAnimalIdentifier(animal, updated)) {
            throw new Error("Identifier already exists")
        }
        await this._animalApi.updateIdentifier(
            animal.panonIdentifier.id,
            {
                oldIdentifier: original,
                newIdentifier: updated,
            });
    }

    async addAnimalIdentifier(animal: JsonAnimal, newIdentifier: AnimalIdentifier): Promise<void> {
        if (this.hasAnimalIdentifier(animal, newIdentifier)) {
            throw new Error("Identifier already exists")
        }
        await this._animalApi.updateIdentifier(
            animal.panonIdentifier.id,
            {
                oldIdentifier: null,
                newIdentifier: newIdentifier,
            });
    }

    async deleteAnimalIdentifier(animal: JsonAnimal, ai: AnimalIdentifier) {
        if (!this.hasAnimalIdentifier(animal, ai)) throw new Error("No such identifier");
        await this._animalApi.deleteIdentifiers(
            animal.panonIdentifier.id,
            {identifiers: [ai]});
    }

    async loadHerd(tag: string): Promise<JsonAnimal[]> {
        const fetchedHerd = tag === "all" ? await this._animalApi.getAll() : await this._animalApi.getByTags([tag]);
        return fetchedHerd.items;
    }

    async createForHerd(animal: CreateAnimalDto): Promise<AnimalController$CreateAnimalResult> {
        const createResult = await this.createForPedigree(animal);
        await this._animalApi.makeHerdMember(createResult.id);
        return createResult;
    }

    async createForPedigree(animal: CreateAnimalDto): Promise<AnimalController$CreateAnimalResult> {
        animal.dateOfBirth = (animal.dateOfBirth as DateTime).toISODate();
        return this._animalApi.create(animal);
    }

    async loadJsonAnimalById(id: string): Promise<JsonAnimal> {
        return this._animalApi.get(id);
    }

    async loadJsonAnimalByPanonId(panonId: string): Promise<JsonAnimal> {
        return (await this._animalApi.getByPanonId(panonId)).item;
    }

    async loadJsonAnimalsByPanonIds(panonIds: string[]): Promise<JsonAnimal[]> {
        const uniquePanonIds = new Set(panonIds);
        return (await this._animalApi.getBulkByPanonIds(Array.from(uniquePanonIds))).items;
    }

    async updateBaseData(animal: JsonAnimal, updateData: AnimalBaseData): Promise<void> {
        /*
        * Exclude chipNumber, pregnancyId and panonIdentifier from the data that will overwrite the original animal.
        * The pregnancyId is excluded because the breeder does not have a way of entering that information in the editor form.
        * The chipNumber and panonIdentifier are excluded because it would be rebuilt with a new chip number upon entering, potentially
        * overwriting all existing chip/id numbers. There will be a separate widget for animal identifications which
        * allows editing as well. The chipNumber is still needed for the "create animal" case, where the BaseData is used as well.
        * */
        const {chipNumber, panonIdentifier, ...rest} = updateData;
        const updatedJsonAnimal: JsonAnimal = {...animal, ...rest};

        await this.updateJsonAnimal(updatedJsonAnimal);
    }

    async activateAnimal(panonId: PanonIdentifier): Promise<void> {
        let updateResult = await this._animalApi.activate(panonId.id);
        this.validateUpdateResult(updateResult)
    }

    async deactivateAnimal(panonId: PanonIdentifier): Promise<void> {
        let updateResult = await this._animalApi.deactivate(panonId.id);
        this.validateUpdateResult(updateResult)
    }

    getAnimalFullName(animal: Pick<JsonAnimal, 'herdCode' | 'name'> | undefined): string {
        return animal
            ? [animal.herdCode, animal.name].join(' ')
            : '';
    }

    hasAnimalIdentifier(animal: JsonAnimal, identifier: AnimalIdentifier): boolean {
        return animal.panonIdentifier.animalIdentifiers.some(ai => animalIdentifiersAreEqual(ai, identifier));
    }

    private async updateJsonAnimal(animal: JsonAnimal) : Promise<void> {
        const dateOfBirth = animal.dateOfBirth;
        if (DateTime.isDateTime(dateOfBirth)) {
            animal.dateOfBirth = dateOfBirth.toISODate();
        }

        const result = await this._animalApi.update(animal);
        this.validateUpdateResult(result);
    }

    private validateUpdateResult(result: UpdateResult) {
        if (result.info !== "updated") {
            throw new Error("Server update failed");
        }
    }

}

export const AnimalService: IAnimalService = new AnimalServiceImpl(animalApi);
