import React, {FunctionComponent} from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import TreatmentType from "../../../api/dtos/treatment-type";
import {InputLabel, MenuItem, Select, TextField} from "@mui/material";
import TreatmentForm, {TreatmentFormBaseProps} from "./treatment-form";
import {TreatmentListJson} from "../../../api/generated/medical-rest";
import useFormFields from "../../Common/hooks/use-form-fields";
import TreatmentService from "../../../services/treatment-service";


interface MedicationFormProps extends TreatmentFormBaseProps {
    prefill?: TreatmentListJson

}

const MedicationForm: FunctionComponent<MedicationFormProps> = (props) => {

    const {formFields, createChangeHandler} = useFormFields<TreatmentListJson>({
        ...TreatmentService.EMPTY_TREATMENT_LIST_JSON,
        ...props.prefill
    });

    return (
        <TreatmentForm onFormSave={props.onFormSave}
                       onFormCancel={props.onFormCancel}
                       onTimestampChange={createChangeHandler("timestamp")}
                       onNoteChange={createChangeHandler("note")}
                       onPrivateChange={createChangeHandler('isPrivate')}
                       treatment={formFields}>
            <InputLabel id="select-medication-type-label">Medikationsart</InputLabel>
            <Select
                labelId="select-medication-type-label"
                id="medication-type-select"
                value={formFields.type}
                onChange={createChangeHandler("type")}
            >
                <MenuItem key={TreatmentType.vaccination} value={TreatmentType.vaccination}>Impfung</MenuItem>
                <MenuItem key={TreatmentType.vitaminsAndMinerals} value={TreatmentType.vitaminsAndMinerals}>Vitamine und Mineralstoffe</MenuItem>
                <MenuItem key={TreatmentType.antiparasitics} value={TreatmentType.antiparasitics}>Parasitenbehandlung</MenuItem>
                <MenuItem key={TreatmentType.otherMedication} value={TreatmentType.otherMedication}>Sonstige Medikation</MenuItem>
            </Select>

            <TextField
                id="medication-drug"
                label="Medikament"
                type="text"
                value={formFields.drug}
                onChange={createChangeHandler("drug")}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
            />


            <TextField
                id="medication-dose"
                label="Dosierung"
                type="number"
                value={formFields.dose}
                onChange={createChangeHandler("dose", (value) => parseFloat(value))}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <Select value={formFields.unit} onChange={createChangeHandler("unit")}>
                            <MenuItem value="ml">ml</MenuItem>
                            <MenuItem value="mg">mg</MenuItem>
                            <MenuItem value="g">g</MenuItem>
                            <MenuItem value="IE">IE</MenuItem>
                            <MenuItem value="-">-</MenuItem>
                        </Select>
                    </InputAdornment>,
                }}
                variant="standard"
            />
        </TreatmentForm>
    );
};

export default MedicationForm;