import {List, Pagination, SxProps} from "@mui/material";
import {TreatmentListItem} from "./treatment-list-item";
import * as React from "react";
import {useState} from "react";
import {TreatmentListJson} from "../../../api/generated/medical-rest";

type PaginatedTreatmentListProps = {
    treatments: TreatmentListJson[];
    onTreatmentClick?: (treatment: TreatmentListJson) => void;
    onTreatmentDelete?: (treatmentId: string) => void;
};

const styles = {
    root: {
        overflowY: "auto"
    } satisfies SxProps,
};

const PAGE_SIZE: number = 5;

export const PaginatedTreatmentList = ({
                                           treatments,
                                           onTreatmentClick,
                                           onTreatmentDelete
                                       }: PaginatedTreatmentListProps) => {
    const [currentPage, setCurrentPage] = useState(1);

    const pageCount = Math.ceil(treatments.length / PAGE_SIZE);
    const pageStartIndex = (currentPage - 1) * PAGE_SIZE;
    const pageEndIndex = currentPage >= pageCount ? treatments.length : currentPage * PAGE_SIZE;
    const treatmentsPage = treatments.slice(pageStartIndex, pageEndIndex);

    return (
        <>
            <List sx={styles.root}>
                {treatmentsPage
                    .map((treatment) => <TreatmentListItem
                        key={treatment.id}
                        treatment={treatment}
                        onClick={onTreatmentClick}
                        onTreatmentDelete={onTreatmentDelete}/>)
                }
            </List>
            {pageCount > 1 &&
                <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={(event, page) => setCurrentPage(page)}></Pagination>
            }
        </>
    );
};