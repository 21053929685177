// @flow
import React, {FunctionComponent} from 'react';
import AnimalSearchResult from "../../api/dtos/animal-search-result";
import TabularTextCard from "../AnimalReview/tabular-text-card";
import {useTranslation} from "react-i18next";

interface AnimalTabularTextCardProps {
    animalSearchResult: AnimalSearchResult,
    handleClick?: (clickedResult: AnimalSearchResult) => void,
    clickable?: boolean
}

const AnimalSearchResultCard: FunctionComponent<AnimalTabularTextCardProps> = ({animalSearchResult, handleClick, clickable}) => {
    const {t} = useTranslation();

    const transformSearchResult = (searchResult: AnimalSearchResult): Map<string, any> => {
        return new Map([
            ["Geburtsdatum", searchResult.dateOfBirth ? searchResult.dateOfBirth.toLocaleString() : "n.a."],
            ["Rasse", t('breed.' + searchResult.breed)],
            ["Farbe", t('color.' + searchResult.fiberColor)],
            ["Zusatzfarben", searchResult.secondaryColors?.map(c => t('color.' + c)).join(', ') ?? ''],
            ["Augenfarbe", searchResult.eyeColor ? t('eyecolor.' + searchResult.eyeColor) : ''],
            ["Chipnummern", searchResult.panonId.animalIdentifiers.map(aId => aId.value).join(", ")],
            ["Register", searchResult.registries.length > 0 ? searchResult.registries.map(sr => sr.name).join(", ") : []],
        ]);
    }


    return clickable && handleClick ?
        (<TabularTextCard clickable={clickable} onClick={() => handleClick(animalSearchResult)} title={animalSearchResult.herdCode + " " + animalSearchResult.name}
                          displayPairs={transformSearchResult(animalSearchResult)}/>) :
        (<TabularTextCard clickable={clickable} title={animalSearchResult.herdCode + " " + animalSearchResult.name} displayPairs={transformSearchResult(animalSearchResult)}/>);
};

export default AnimalSearchResultCard;