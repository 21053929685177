import {DateTime} from "luxon";

export default interface ReviewSimilaritiesComparisonDto {
    comparisonPairs: ComparisonPair[],
    avatarUrlReviewAnimal: string,
    avatarUrlSimilarAnimal: string
}

type ComparisonValue = string | number | DateTime;

export class ComparisonPair {
    constructor(
        private readonly _name: string,
        private readonly _firstValue: ComparisonValue,
        private readonly _secondValue: ComparisonValue,
        private readonly _translationHint?: string) {
    }

    public isEqual(): boolean {
        if (DateTime.isDateTime(this.firstValue)) {
            const dt1 = this.firstValue as DateTime;
            const dt2 = this.secondValue as DateTime;
            return dt1.toMillis() === dt2.toMillis();
        }
        return this.firstValue === this.secondValue;
    }

    get name(): string {
        return this._name;
    }

    get firstValue(): ComparisonValue {
        return this._firstValue;
    }

    get secondValue(): ComparisonValue {
        return this._secondValue;
    }

    get translationHint(): string | undefined {
        return this._translationHint;
    }
}