import React from 'react';
import {Widget} from "../../Common";
import {JsonAnimal} from "../../../api/generated/rest-dto";
import {useHistory} from "react-router-dom";
import RouteService from "../../../services/route-service";
import TreatmentType from "../../../api/dtos/treatment-type";
import {
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis
} from 'recharts';
import {
    Box,
    IconButton,
    Paper,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {DateTime} from "luxon";
import {Balance, Delete, Edit, Filter3} from "@mui/icons-material";
import Tabbed from '../../Common/tabbed';
import {TreatmentListJson} from "../../../api/generated/medical-rest";

type WeightChartProps = {
    animal: JsonAnimal;
    weights: ChartPoint[];
    bodyscores: ChartPoint[];
    onTreatmentDelete: (treatmentId: string) => void;
};

type ChartPoint = {
    treatmentId: string;
    treatment?: TreatmentListJson,
    date: DateTime;
    dateString: string;
    weight?: number;
    bodyscore?: number;
    note: string;
    delta?: number;
};

const CustomTooltip = ({active, payload}: TooltipProps<string, string>) => {
    if (active && payload?.length) {
        const data = payload[0].payload;
        return (
            <Box>
                <p>Datum: {DateTime.isDateTime(data.date) ? data.date.toLocaleString() : data.date}</p>
                {data.weight !== undefined && <p>Gewicht: {data.weight}kg</p>}
                {data.bodyscore !== undefined && <p>Bodyscore: {data.bodyscore}</p>}
                {data.note && <p>Notiz: {data.note}</p>}
            </Box>
        );
    }

    return null;
};

const legendStyles: SxProps = {
    display: "flex",
    gap: 1,
    justifyContent: "center",
    ".weight": {
        color: "#8884d8"
    },
    ".bodyscore": {
        color: "#777b27"
    }
} as const;

type ChartProps = { data: ChartPoint[] };

const ResponsiveWeightChart = ({data}: ChartProps) => (
    <ResponsiveContainer>
        <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="dateString"/>
            <YAxis type="number" unit="kg" domain={[0, 120]} yAxisId="weight" orientation="left"/>
            <YAxis type="number" unit="" domain={[0, 6]} yAxisId="bodyscore" orientation="right"/>
            <Tooltip content={<CustomTooltip/>}/>
            <Legend content={
                <Box sx={legendStyles}>
                    <Typography className="weight">Gewicht</Typography>
                    <Typography className="bodyscore">Bodyscore</Typography>
                </Box>
            }/>
            <Line name="Gewicht" yAxisId="weight" strokeWidth={3} type="monotone" dataKey="weight" stroke="#8884d8"
                  activeDot={{r: 8}} connectNulls/>
            <Line name="Bodyscore" yAxisId="bodyscore" strokeWidth={2} type="monotone" dataKey="bodyscore" stroke="#777b27"
                  activeDot={{r: 8}} connectNulls/>
        </ComposedChart>
    </ResponsiveContainer>
);

const WeightTableRow = ({row, deleteTreatment, editTreatment}: {
    row: ChartPoint,
    deleteTreatment: WeightChartProps['onTreatmentDelete'],
    editTreatment: (treatment: TreatmentListJson) => void,
}) => {
    const handleEdit = () => row.treatment && editTreatment(row.treatment);
    const handleDelete = () => {
        const type = row.weight ? 'Wiegung' : 'Bodyscore';
        if (window.confirm(`${type} löschen? Diese Aktion kann nicht rückgängig gemacht werden!`)) {
            deleteTreatment?.(row.treatmentId);
        }
    };

    const isLosingWeight = (row.delta ?? 0) < 0;
    return (
        <TableRow>
            <TableCell>{row.dateString}</TableCell>
            <TableCell sx={isLosingWeight ? {color: 'red'} : {}}>
                {row.weight === undefined ? '—' : `${row.weight} kg`}
            </TableCell>
            <TableCell>{row.bodyscore ?? '—'}</TableCell>
            <TableCell>{row.note}</TableCell>
            <TableCell>
                {row.treatment && <IconButton onClick={handleEdit}><Edit/></IconButton>}
                <IconButton onClick={handleDelete}><Delete/></IconButton>
            </TableCell>
        </TableRow>
    );
};

const WeightTable = ({data, deleteTreatment, editTreatment}: ChartProps & {
    deleteTreatment: WeightChartProps['onTreatmentDelete'],
    editTreatment: (treatment: TreatmentListJson) => void,
}) => (
    <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Datum</TableCell>
                    <TableCell>Gewicht</TableCell>
                    <TableCell>Bodyscore</TableCell>
                    <TableCell>Notiz</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(row => (
                    <WeightTableRow key={row.treatmentId} row={row} deleteTreatment={deleteTreatment} editTreatment={editTreatment}/>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

const findMinDate = (points: ChartPoint[]) => {
    let minDate = points[0]?.date;
    for (const point of points) {
        if (point.date < minDate) {
            minDate = point.date;
        }
    }
    return minDate;
};

const findMaxDate = (points: ChartPoint[]) => {
    let maxDate = points[0]?.date;
    for (const point of points) {
        if (point.date > maxDate) {
            maxDate = point.date;
        }
    }
    return maxDate;
};

const spaceDataPoints = (dataPoints: ChartPoint[]) => {
    const minDate = findMinDate(dataPoints);
    const maxDate = findMaxDate(dataPoints);
    const range = Math.round(maxDate?.diff(minDate).as('days'));

    const dummyDataPoints: ChartPoint[] = [];
    if (minDate !== undefined) {
        for (let i = 0; i < range; ++i) {
            const date = minDate.plus({days: i});
            const point: ChartPoint = {
                treatmentId: 'dummy-' + i,
                date,
                dateString: date.toLocaleString(),
                note: '',
            };
            dummyDataPoints.push(point);
        }
    }

    return [...dataPoints, ...dummyDataPoints];
};

const compareDates = <T extends { date: DateTime; }>(a: T, b: T) => a.date.toMillis() - b.date.toMillis();

const WeightChart = (props: WeightChartProps) => {
    const history = useHistory();
    const {animal} = props;

    const weights = [...props.weights]
        .sort(compareDates)
        .map((weight, idx, weights) => ({
            ...weight,
            delta: (weight.weight ?? 0) - (weights[idx - 1]?.weight ?? 0),
        }));

    const dataPoints = [
        ...weights,
        ...props.bodyscores,
    ].sort(compareDates);
    const spacedDataPoints = spaceDataPoints(dataPoints);

    const pointsByDate = new Map<string, ChartPoint>();
    for (const chartPoint of spacedDataPoints) {
        const key = chartPoint.dateString;
        const value = pointsByDate.get(key) ?? {...chartPoint};
        value.weight ??= chartPoint.weight;
        value.bodyscore ??= chartPoint.bodyscore;
        pointsByDate.set(key, value);
    }

    const mergedDataPoints = Array.from(pointsByDate.values())
        .sort((a, b) => a.date.toMillis() - b.date.toMillis());

    const navigateToTreatmentEditor = (treatmentType: TreatmentType, treatment?: TreatmentListJson) => history.push({
        pathname: RouteService.expand(RouteService.TREATMENT_EDIT, {animalId: animal.id}),
        search: `?treatmentType=${treatmentType}&panonId=${animal.panonIdentifier.id}`,
        state: treatment ? {treatment} : undefined
    });

    const handleTreatmentClick = (treatment: TreatmentListJson) => navigateToTreatmentEditor(treatment.type as TreatmentType, treatment);

    const recordTreatment = (treatmentType: TreatmentType) => history.push({
        pathname: RouteService.expand(RouteService.TREATMENT_EDIT, {animalId: props.animal.id}),
        search: `?treatmentType=${treatmentType}&panonId=${props.animal.panonIdentifier.id}`,
    });

    const recordWeightAction = () => recordTreatment(TreatmentType.weighing);
    const recordBodyScoreAction = () => recordTreatment(TreatmentType.bodyscore);

    return <Widget displayName="Gewicht"
                   cardMenuEntries={[{
                       actionName: "Gewicht erfassen",
                       actionFunction: recordWeightAction
                   }, {
                       actionName: "Bodyscore eintragen",
                       actionFunction: recordBodyScoreAction,
                   }]}
                   quickActions={[{
                       icon: <Balance/>,
                       action: recordWeightAction,
                   }, {
                       icon: <Filter3 />,
                       action: recordBodyScoreAction,
                   }]}>
        <Tabbed>
            <Tabbed.Tab label="Chart">
                <Box sx={{height: "300px", width: "100%"}}>
                    <ResponsiveWeightChart data={mergedDataPoints}/>
                </Box>
            </Tabbed.Tab>
            <Tabbed.Tab label="Tabelle">
                <WeightTable data={dataPoints} deleteTreatment={props.onTreatmentDelete} editTreatment={handleTreatmentClick}/>
            </Tabbed.Tab>
        </Tabbed>
    </Widget>;
};

export default WeightChart;