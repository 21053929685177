import React, {useState} from "react";
import {PregnancyService} from "../services/pregnancy-service";
import {
    AvatarGroup,
    LinearProgress,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Stack
} from "@mui/material";
import List from "@mui/material/List";
import {PregnancyListJson} from "../api/generated/medical-rest";
import {JsonAnimal} from "../api/generated/rest-dto";
import PregnancyDateRange from "../components/AnimalDashboard/Pregnancy/pregnancy-date-range";
import FlexStack from "../components/Common/flex-stack";
import {LoadingAvatarListItem} from "../components/Common/loading-avatar-list-item";
import Typography from "@mui/material/Typography";
import RouteService from "../services/route-service";
import {Link as RouterLink} from "react-router-dom";
import {AnimalAvatar} from "../components/Common/animal-avatar";
import {ErrorBox, InfoBox} from "../components/Common/alerts";
import {useQuery} from "@tanstack/react-query";
import {DateTime} from "luxon";
import IsoIcon from "@mui/icons-material/Iso";
import {
    getEstimatedPregnancyDurationDays,
    getPregnancyDurationDays,
    PregnancyIcon
} from "../components/AnimalDashboard/Pregnancy/pregnancy-utils";
import Avatar from "@mui/material/Avatar";
import {DatePicker} from "@mui/x-date-pickers";
import {Widget} from "../components/Common";
import {useBulkAnimals} from "../hooks/use-bulk-animals";
import {AnimalService} from "../services/animal-service";
import {PregnancyProgress} from "../components/AnimalDashboard/Pregnancy/pregnancy-progress";

export const useHerdPregnancies = () => {
    const queryInfo = useQuery({
        queryKey: ['herd-pregnancies'],
        queryFn: () => PregnancyService.listActive(),
    });

    return {
        ...queryInfo,
        pregnancies: queryInfo.data,
    };
};

export const useHerdBirths = (after: DateTime, before: DateTime | null) => {
    const queryInfo = useQuery({
        queryKey: ['herd-births', {after, before}],
        queryFn: () => PregnancyService.listBirths(after, before ?? undefined),
    });
    return {
        ...queryInfo,
        births: queryInfo.data,
    };
};

const PregnancyListItem = (props: { pregnancy: PregnancyListJson, parents: Map<string, JsonAnimal> }) => {
    const {pregnancy, parents} = props;

    const fullName = (animal: JsonAnimal | undefined) => animal ? `${animal.herdCode} ${animal.name}` : '';

    const dam = parents.get(pregnancy.damId);
    const sire = pregnancy.sireId ? parents.get(pregnancy.sireId) : null;

    return (
        <ListItem>
            <ListItemButton component={RouterLink}
                            to={{pathname: RouteService.expand(RouteService.PREGNANCY_DETAILS, {pregnancyId: pregnancy.id})}}>
                <ListItemAvatar sx={{pr: 2}}>
                    <AvatarGroup>
                        <AnimalAvatar animal={dam ?? {id: ''}}/>
                        <AnimalAvatar animal={sire ?? {id: ''}}/>
                    </AvatarGroup>
                </ListItemAvatar>
                <ListItemText>
                    <Stack>
                        <PregnancyDateRange pregnancy={pregnancy}/>
                        <PregnancyProgress pregnancy={pregnancy}/>
                        <Typography>Vater: {sire ? fullName(sire) : '---'}</Typography>
                        <Typography>Mutter: {fullName(dam)}</Typography>
                    </Stack>
                </ListItemText>
            </ListItemButton>
        </ListItem>
    );
};

const BirthListItem = (props: { birth: PregnancyListJson, animalsById: Map<string, JsonAnimal> }) => {
    const {birth, animalsById} = props;

    const fullName = (animal: JsonAnimal | undefined) => animal ? `${animal.herdCode} ${animal.name}` : '';

    const dam = animalsById.get(birth.damId);
    const sire = birth.sireId ? animalsById.get(birth.sireId) : null;
    const offspring = birth.offspringId ? animalsById.get(birth.offspringId) : null;

    return (
        <ListItem>
            <ListItemButton component={RouterLink}
                            to={{pathname: RouteService.expand(RouteService.PREGNANCY_DETAILS, {pregnancyId: birth.id})}}>
                <ListItemAvatar sx={{pr: 2}}>
                    <AvatarGroup>
                        {offspring
                            ? <AnimalAvatar animal={offspring}/>
                            : <Avatar><PregnancyIcon pregnancy={birth}/></Avatar>}
                        <AnimalAvatar animal={dam ?? {id: ''}}/>
                        <AnimalAvatar animal={sire ?? {id: ''}}/>
                    </AvatarGroup>
                </ListItemAvatar>
                <ListItemText>
                    <Stack>
                        {!!offspring && <strong>{AnimalService.getAnimalFullName(offspring)}</strong>}
                        <span>
                        {birth.actualEndDate
                            ? birth.actualEndDate.toLocaleString()
                            : <><IsoIcon sx={{verticalAlign: "bottom"}}/>{birth.calculatedEndDate.toLocaleString()}</>}
                            {!!birth.startDate && <> ({getPregnancyDurationDays(birth)}/{getEstimatedPregnancyDurationDays(birth)} Tage)</>}
                            <PregnancyProgress pregnancy={birth}/>
                        </span>
                        <Typography>Vater: {sire ? fullName(sire) : '---'}</Typography>
                        <Typography>Mutter: {fullName(dam)}</Typography>
                    </Stack>
                </ListItemText>
            </ListItemButton>
        </ListItem>
    );
};

export const HerdPregnancies = () => {
    const [after, setAfter] = useState(() => DateTime.now().minus({years: 1}));
    const [before, setBefore] = useState<DateTime | null>(null);

    const {pregnancies = [], isLoading: isPregnanciesLoading, isError: isPregnanciesError} = useHerdPregnancies();
    const {births = [], isLoading: isBirthsLoading, isError: isBirthsError} = useHerdBirths(after, before);

    const offspringIds = [...pregnancies, ...births].map(p => p.offspringId);
    const parentIds = [...pregnancies, ...births].flatMap(p => [p.damId, p.sireId]);
    const {animals: animalsById, isLoading: isParentsLoading, isError: isParentsError}
        = useBulkAnimals([...parentIds, ...offspringIds].filter((p): p is string => !!p));

    const isError = isPregnanciesError || isBirthsError || isParentsError;
    const isLoading = isPregnanciesLoading || isBirthsLoading || isParentsLoading;

    if (isLoading) {
        return (
            <FlexStack>
                <LinearProgress/>

                <List>
                    <LoadingAvatarListItem/>
                    <LoadingAvatarListItem/>
                    <LoadingAvatarListItem/>
                </List>
            </FlexStack>
        );
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden der Trächtigkeiten</ErrorBox>;
    }

    return (
        <FlexStack>
            <Widget displayName="Trächtigkeiten">
                {pregnancies.length
                    ? (
                        <List>
                            {pregnancies.map(pregnancy =>
                                <PregnancyListItem key={pregnancy.id}
                                                   parents={animalsById}
                                                   pregnancy={pregnancy}/>)}
                        </List>
                    )
                    : <InfoBox>Keine trächtigen Tiere in der Herde</InfoBox>}
            </Widget>

            <Widget displayName="Geburten">
                <Stack direction="row" gap={4}>
                    <DatePicker label="Von" value={after} onChange={value => value && setAfter(value)}/>
                    <DatePicker label="Bis" value={before} onChange={value => setBefore(value)}/>
                </Stack>

                {births.length
                    ? (
                        <List>
                            {births.map(birth => <BirthListItem key={birth.id} animalsById={animalsById} birth={birth}/>)}
                        </List>
                    )
                    : <InfoBox>Keine Geburten im gewählten Zeitraum</InfoBox>}
            </Widget>
        </FlexStack>
    );
};