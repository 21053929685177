import React, {FunctionComponent, useState} from 'react';
import Button from '@mui/material/Button';
import {Box, SxProps, Theme} from "@mui/material";
import TextField from "@mui/material/TextField";
import {DateTime} from "luxon";
import {JsonAnimal} from "../api/generated/rest-dto";
import {RecordMatingJson} from "../api/generated/medical-rest";
import useFormFields from "../components/Common/hooks/use-form-fields";
import {useHistory, useLocation} from "react-router-dom";
import FlexStack from "../components/Common/flex-stack";
import {PregnancyService} from "../services/pregnancy-service";
import RouteService from "../services/route-service";
import {SexValues} from "../api/generated/herd-animal";
import {DatePicker} from "@mui/x-date-pickers";
import ParentPicker from "../components/Pedigree/parent-picker";
import {useGlobalSnackbarStore} from "../stores/global-snackbar-store";


interface MatingLocationState {
    mother: JsonAnimal
}

const styles: Record<string, SxProps<Theme>> = {
    button: {
        margin: 1
    }
};

const MatingCreate: FunctionComponent = () => {

    const defaultMating: RecordMatingJson = {
        sireId: "",
        damId: "",
        note: "",
        matingDate: DateTime.now()
    };

    const {error, success} = useGlobalSnackbarStore((state) => state);

    const history = useHistory();
    const locationState: MatingLocationState = useLocation<MatingLocationState>().state;

    const {formFields, createChangeHandler} = useFormFields({...defaultMating});
    const [selectedSire, setSelectedSire] = useState<JsonAnimal | undefined>()

    //Guard against direct calls of the page without any locationState.
    if (!(locationState?.mother)) {
        history.push(RouteService.HOME);
        return null;
    }

    const mother: JsonAnimal = locationState.mother;
    const sourceRoute = RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId: mother.panonIdentifier.id});

    const handleCreate = async () => {
        const createResult = await PregnancyService.recordMating({...formFields, damId: mother.panonIdentifier.id});
        const successRoute = RouteService.expand(RouteService.PREGNANCY_DETAILS, {pregnancyId: createResult.id});
        success("Deckung wurde erfasst!", successRoute);
    };

    const matingDateChangeHandler = createChangeHandler("matingDate");

    const handleSireSelected = (selectedSire: JsonAnimal) => {
        if(selectedSire.sex === SexValues.MALE) { //just to make sure we're setting a male as sire
            formFields.sireId = selectedSire.panonIdentifier.id;
            setSelectedSire(selectedSire);
        } else {
            error('Das Geschlecht des Vaters muss "Männlich" sein!');
        }
    }

    return (
        <FlexStack>
            <DatePicker
                label="Datum der Deckung"
                value={formFields.matingDate}
                onChange={matingDateChangeHandler}
            />

            <TextField
                id="note-textarea"
                label="Notiz"
                placeholder="Notiz"
                value={formFields.note}
                onChange={createChangeHandler("note")}
                multiline
            />
            <ParentPicker onParentSelected={handleSireSelected} damEditable={false} dam={mother} sire={selectedSire}/>
            <Box>
                <Button sx={styles.button} onClick={() => history.push(sourceRoute)} variant="contained"
                        color="primary">
                    Abbrechen
                </Button>
                <Button sx={styles.button} onClick={handleCreate} variant="contained" color="primary">
                    Speichern
                </Button>
            </Box>
        </FlexStack>
    );
};

export default MatingCreate;
