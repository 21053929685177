// @flow
import React, {FunctionComponent, PropsWithChildren, useCallback} from 'react';
import FlexStack from "../components/Common/flex-stack";
import {Box, Button, IconButton, Stack, TextField, Typography} from "@mui/material";
import {Widget} from "../components/Common";
import {Path, useFieldArray, useForm} from "react-hook-form";
import {FarmData} from "../api/dtos/breeder-update";
import PersonAddIcon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import {useMutation} from "@tanstack/react-query";
import {InfoBox} from "../components/Common/alerts";
import AuthService from "../services/auth-service";

type SignupData = FarmData & {
    password: string,
};

const requiredFieldConfig = {required: {value: true, message: "Pflichtfeld"}}; //TODO get i18n to work

const FlexWidget = (props: PropsWithChildren<{ displayName: React.ReactNode }>) => (
    <Widget displayName={props.displayName}>
        <FlexStack>
            {props.children}
        </FlexStack>
    </Widget>
);


const SignupBreeder: FunctionComponent = () => {
    const form = useForm<SignupData>({
        defaultValues: {
            owners: [
                { name: '' },
            ],
        },
    });
    const {formState: {errors}, register, handleSubmit, control} = form;

    const {
        fields: owners,
        append: appendOwner,
        remove: removeOwner
    } = useFieldArray({control, name: "owners"})

    const addOwner = useCallback(() => appendOwner({name: ""}), [appendOwner]);

    const {mutate: signup, isPending} = useMutation({
        mutationKey: ['signup'],
        mutationFn: async (signupData: SignupData) => await AuthService.signUp({
            ...signupData,
            email: signupData.emailAddress,
            farm: {
                ...signupData,
                owners: signupData.owners.map(p => p.name).filter(Boolean),
            },
        }),
        onError: () => alert('Registrierung fehlgeschlagen. Bitte wende dich an den Administrator.'),
        onSuccess: async () => {
            alert('Registrierung erfolgreich. Du kannst dich nun einloggen.');
            await AuthService.doLogin();
        },
    });

    const registerRequired = useCallback((fieldName: Path<SignupData>) => ({
        ...register(fieldName, requiredFieldConfig),
        required: true,
    }), [register]);

    return (
        <form onSubmit={handleSubmit(form => signup(form))} noValidate>
            <FlexStack spacing={4}>
                <Box>
                    <Typography variant="h4">Registriere dich als Züchter</Typography>
                </Box>

                <FlexWidget displayName="Benutzerdaten">
                    <TextField
                        {...registerRequired('emailAddress')}
                        label="E-Mailadresse"
                        error={!!errors.emailAddress}
                        helperText={errors.emailAddress?.message ?? ''}/>
                    <TextField
                        {...registerRequired('username')}
                        label="Benutzername"
                        error={!!errors.username}
                        helperText={errors.username?.message ?? ''}/>
                    <TextField
                        {...registerRequired('password')}
                        label="Passwort"
                        error={!!errors.password}
                        helperText={errors.password?.message ?? ''}/>
                </FlexWidget>

                <FlexWidget displayName="Hofdaten">
                    <TextField
                        {...registerRequired('farmName')}
                        label="Hofname"
                        error={!!errors.farmName}
                        helperText={errors.farmName?.message ?? ''}/>
                    <TextField
                        {...registerRequired('herdCode')}
                        label="Herdkürzel"
                        error={!!errors.herdCode}
                        helperText={errors.herdCode?.message ?? ''}/>
                    <TextField label="Webseite" {...register("website")}/>
                    <TextField label="Telefonnummer" {...register("phone")}/>
                    <TextField label="Steuernummer" {...register("vatNumber")}/>
                </FlexWidget>

                <FlexWidget displayName="Adresse">
                    <TextField
                        {...registerRequired('address.country')}
                        label="Land"
                        error={!!errors.address?.country}
                        helperText={errors.address?.country?.message ?? ''}/>
                    <TextField
                        {...registerRequired('address.state')}
                        label="Bundesland"
                        error={!!errors.address?.state}
                        helperText={errors.address?.state?.message ?? ''}/>
                    <TextField
                        {...registerRequired('address.zipCode')}
                        label="PLZ"
                        error={!!errors.address?.zipCode}
                        helperText={errors.address?.zipCode?.message ?? ''}/>
                    <TextField
                        {...registerRequired('address.city')}
                        label="Stadt"
                        error={!!errors.address?.city}
                        helperText={errors.address?.city?.message ?? ''}/>
                    <TextField
                        {...registerRequired('address.street')}
                        label="Straße und Hausnummer"
                        error={!!errors.address?.street}
                        helperText={errors.address?.street?.message ?? ''}/>
                </FlexWidget>

                <FlexWidget displayName={(
                    <>
                        Besitzer
                        <IconButton color="primary" aria-label="add owner" onClick={addOwner}>
                            <PersonAddIcon/>
                        </IconButton>
                    </>
                )}>
                    {owners.map((field, index) => (
                        <Stack key={field.id} direction="row" alignItems="baseline" gap={2}>
                            <TextField
                                fullWidth
                                {...registerRequired(`owners.${index}.name`)}
                                label="Vorname, Nachname"
                                error={!!errors.owners?.[index]?.name}
                                helperText={errors.owners?.[index]?.name?.message ?? ''}/>
                            <IconButton color="primary"
                                        size="small"
                                        aria-label="remove owner"
                                        onClick={() => removeOwner(index)}>
                                <PersonRemoveIcon/>
                            </IconButton>
                        </Stack>
                    ))}
                    {!owners.length && (
                        <InfoBox>
                            Keine Besitzer hinterlegt. <Button onClick={addOwner}>Besitzer hinzufügen</Button>.
                        </InfoBox>
                    )}
                </FlexWidget>

                <Stack direction="row" justifyContent="right">
                    <Button variant="contained" type="submit" disabled={isPending}>Registrierung abschließen</Button>
                </Stack>
            </FlexStack>
        </form>
    );
};

export default SignupBreeder
