import Typography from "@mui/material/Typography";
import FlexStack from "../Common/flex-stack";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid2 as Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React, {PropsWithChildren} from "react";
import AuthService from "../../services/auth-service";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import RouteService from "../../services/route-service";
import {AnimalRecordStatusValues} from "../../api/generated/herd-animal";
import {useHistory} from "react-router-dom";
import {BreederService} from "../../services/breeder-service";
import {useSuspenseQuery} from "@tanstack/react-query";
import {LoadingSuspense} from "../Common/loading-suspense";
import {Edit, Warning} from "@mui/icons-material";
import {useNav} from "../Common/hooks/use-nav";
import {BreederController$BreederDetails} from "../../api/generated/breeder-controller";
import {WarningBox} from "../Common/alerts";

const GridCard = ({children, href, image, alt, title, description}: PropsWithChildren<{
    href: string;
    image: string;
    alt: string;
    title: string;
    description: string;
}>) => {
    const history = useHistory();
    return (
        <Grid size={{xs: 12, sm: 6}}>
            <Card>
                <CardActionArea onClick={() => history.push(href)}>
                    <CardMedia
                        component="img"
                        height="240"
                        image={image}
                        alt={alt}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {description}
                        </Typography>
                        {children}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

const isDummyDatum = (s: string) => !s || /^[ -]*$/.test(s);

const Datum = (props: {children:string}) => isDummyDatum(props.children)
    ? <Warning color="warning" fontSize="small"/>
    : props.children;

const useBreederDetails = () => useSuspenseQuery({
    queryKey: ['breeder', 'details'],
    queryFn: async () => await BreederService.ownDetails(),
});

const isBreederDetailsIncomplete = (details: BreederController$BreederDetails) => {
    const required = [
        details.herdCode,
        details.farmName,
        // TODO non-optional fields?
        // details.website, details.phone, details.vatNumber,
        // TODO owners?
        // details.owners,
    ];

    const incompleteBaseData = required.some(x => isDummyDatum(x));
    const incompleteAddress = Object.values(details.address).some(x => isDummyDatum(x));

    return incompleteBaseData || incompleteAddress;
};

const BreederDataCard = (props: PropsWithChildren<{}>) => {
    const nav = useNav();
    const {data: details} = useBreederDetails();
    const address = details.address;

    return (
        <Card>
            <CardHeader title="Deine Daten" action={
                <IconButton className="widget-actions-button"
                            aria-label="widget-actions"
                            onClick={() => nav.push(RouteService.BREEDER_BASE_DATA)}
                            size="large">
                    <Edit/>
                </IconButton>
            }/>
            <CardContent>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>Herdkürzel, Hofname:</TableCell>
                                <TableCell><Datum>{details.herdCode}</Datum>, <Datum>{details.farmName}</Datum></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Adresse:</TableCell>
                                <TableCell>
                                    <div><Datum>{address.street}</Datum></div>
                                    <div>
                                        <Datum>{address.zipCode}</Datum> <Datum>{address.city}</Datum>, <Datum>{address.state}</Datum>, <Datum>{address.country}</Datum>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

const NotificationsCard = () => {
    const nav = useNav();
    const {data: details} = useBreederDetails();

    if (isBreederDetailsIncomplete(details)) {
        return (
            <WarningBox>
                Deine Züchterdaten sind unvollständig.
                <Button variant="text" onClick={() => nav.push(RouteService.BREEDER_BASE_DATA)}>Jetzt vervollständigen</Button>
            </WarningBox>
        );
    }

    return <></>;
};

export const Dashboard = () => {
    return (
        <FlexStack>
            <Stack alignItems="center">
                <Typography variant="h5" sx={{mt: 4}}>Hallo {AuthService.getUsername()}!</Typography>
            </Stack>

            <LoadingSuspense>
                <Container><NotificationsCard/></Container>
                <Container><BreederDataCard/></Container>
            </LoadingSuspense>

            <Divider variant="middle" sx={{m: 2}}/>

            <Container>
                <Grid container spacing={3}>
                    <GridCard href={RouteService.expand(RouteService.HERD_GROUP, {
                        groupId: "all",
                        recordStatus: AnimalRecordStatusValues.HERD
                    })}
                              image="/herd.png"
                              alt="herd overview"
                              title="Herde verwalten"
                              description="Bekomme einen Überblick über deine Herde und erfasse neue Tiere."/>

                    <GridCard href={RouteService.HERD_PREGNANCIES_LIST}
                              image="/pregnancy.png"
                              alt="upcoming pregnancies"
                              title="Geburten planen"
                              description="Alle Trächtigkeiten und bevorstehenden Geburten deiner Herde auf einen Blick."/>

                    <GridCard href={RouteService.REGISTRY_ANIMAL_SEARCH}
                              image="/registry.png"
                              alt="registry search"
                              title="Register durchsuchen"
                              description="Durchsuche das zentrale Register nach Tieren anderer Vereinsmitglieder."/>

                    <GridCard href={RouteService.BREEDERS_LIST}
                              image="/breeders.png"
                              alt="breeder search"
                              title="Züchter finden"
                              description="Züchter und Betriebe suchen und finden."/>

                </Grid>
            </Container>
        </FlexStack>
    );
};