// @flow
import React, {FunctionComponent, useCallback} from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import {FarmData} from "../../api/dtos/breeder-update";
import {Box, Button, IconButton, Stack, TextField} from "@mui/material";
import FlexStack from "../Common/flex-stack";
import PersonAddIcon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import SaveIcon from "@mui/icons-material/Save";
import {Widget} from '../Common';
import {InfoBox} from "../Common/alerts";
import {HourglassTop} from "@mui/icons-material";

interface BreederDetailsFormProps {
    onSubmit: (data: FarmData) => void;
    initialData: FarmData;
}


const requiredFieldConfig = {required: {value: true, message: "Pflichtfeld"}} //TODO get i18n to work

const BreederDetailsForm: FunctionComponent<BreederDetailsFormProps> = ({onSubmit, initialData}) => {
    const {
        formState: {errors, isSubmitting},
        register,
        handleSubmit,
        control,
    } = useForm<FarmData>({
        defaultValues: initialData
    })
    const {
        fields,
        append,
        remove
    } = useFieldArray({control, name: "owners"})

    const addOwner = useCallback(() => append({name: ""}), [append]);

    return (
        <Box>
            <form onSubmit={handleSubmit(form => onSubmit(form))} autoComplete="off" noValidate>
                <FlexStack spacing={4}>

                    <Widget displayName="Hofdaten">
                        <FlexStack>
                            <TextField
                                {...register('farmName', requiredFieldConfig)}
                                label="Hofname"
                                error={!!errors.farmName}
                                helperText={errors.farmName?.message ?? ''}/>
                            <TextField
                                {...register('herdCode', requiredFieldConfig)}
                                label="Herdkürzel"
                                error={!!errors.herdCode}
                                helperText={errors.herdCode?.message ?? ''}/>
                            <TextField label="Webseite" {...register("website")}/>
                            <TextField label="Telefonnummer" {...register("phone")}/>
                            <TextField label="Steuernummer" {...register("vatNumber")}/>
                        </FlexStack>
                    </Widget>

                    <Widget displayName="Adresse">
                        <FlexStack>
                        <TextField
                            {...register('address.country', requiredFieldConfig)}
                            label="Land"
                            error={!!errors.address?.country}
                            helperText={errors.address?.country?.message ?? ''}/>
                        <TextField
                            {...register('address.state', requiredFieldConfig)}
                            label="Bundesland"
                            error={!!errors.address?.state}
                            helperText={errors.address?.state?.message ?? ''}/>
                        <TextField
                            {...register('address.zipCode', requiredFieldConfig)}
                            label="PLZ"
                            error={!!errors.address?.zipCode}
                            helperText={errors.address?.zipCode?.message ?? ''}/>
                        <TextField
                            {...register('address.city', requiredFieldConfig)}
                            label="Stadt"
                            error={!!errors.address?.city}
                            helperText={errors.address?.city?.message ?? ''}/>
                        <TextField
                            {...register('address.street', requiredFieldConfig)}
                            label="Straße und Hausnummer"
                            error={!!errors.address?.street}
                            helperText={errors.address?.street?.message ?? ''}/>
                        </FlexStack>
                    </Widget>

                    <Widget displayName={(
                        <>
                            Besitzer
                            <IconButton color="primary" aria-label="add owner" onClick={addOwner}>
                                <PersonAddIcon/>
                            </IconButton>
                        </>
                    )}>
                        <FlexStack>
                            {fields.map((field, index) => (
                                <Stack key={field.id} direction="row" alignItems="baseline" gap={2}>
                                    <TextField
                                        fullWidth
                                        {...register(`owners.${index}.name`, requiredFieldConfig)}
                                        label="Vorname, Nachname"
                                        error={!!errors.owners?.[index]?.name}
                                        helperText={errors.owners?.[index]?.name?.message ?? ''}/>
                                    <IconButton color="primary"
                                                size="small"
                                                aria-label="remove owner"
                                                onClick={() => remove(index)}>
                                        <PersonRemoveIcon/>
                                    </IconButton>
                                </Stack>
                            ))}
                            {!fields.length && (
                                <InfoBox>
                                    Keine Besitzer hinterlegt. <Button onClick={addOwner}>Besitzer hinzufügen</Button>.
                                </InfoBox>
                            )}
                        </FlexStack>
                    </Widget>

                    <Button variant="contained"
                            startIcon={isSubmitting ? <HourglassTop/> : <SaveIcon/>}
                            type="submit"
                            disabled={isSubmitting}
                    >
                        Speichern
                    </Button>
                </FlexStack>
            </form>
        </Box>
    );
};


export default BreederDetailsForm